import React, {useEffect, useState} from "react";
import {PlusSmIcon} from "@heroicons/react/outline";
import {
  CalendarIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import {useFieldArray, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {useAuth} from "hooks/useAuth";
import {useMutation, useQueryClient} from "react-query";
import {useAxios} from "hooks/useAxios";
import Rating from "components/Forms/Rating";
import ReactQuill from "react-quill";
import SelectWithSupportedText
  from "components/Forms/Select/SelectWithSupportedText2";
import renderFeedbackBadge from "../utils/renderFeedbackBadge";
import RichEditor from "components/RichEditor";

const FeedbackDetails = ({ onClose, feedback }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { user } = useAuth();
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const mutationDraftUpdate = useMutation(
    (newFeedback) => {
      return axios.put(`/v1/feedbacks/draft/${newFeedback.id}`, newFeedback);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedbacks"]);
        onClose();
      },
    }
  );

  const mutationAnswer = useMutation(
    (newFeedback) => {
      return axios.put(`/v1/feedbacks/${newFeedback.id}`, newFeedback);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedbacks"]);
        onClose();
      },
    }
  );
  const { handleSubmit, setValue, watch, control } = useForm({
    defaultValues: {
      remarks: feedback.remarks.map((remark) => {
        return remark.remark;
      }),
      questions: feedback.questions.map((question) => {
        return {
          question: question.question,
          type: question.type,
        };
      }),
    },
  });

  const onSubmit = (data) => {
    setIsEdit(false);
    const updatedFeedback = {
      id: feedback.id,
      subject: feedback.subject,
      writers: [feedback.writer.email],
      receiver: feedback.receiver.email,
      answers: [],
      remarks: [],
      questions: [],
    };

    if (feedback.status == "Awaiting Response") {
      feedback.questions.map((question, index) => {
        if (data.answers && data.answers[index] !== undefined) {
          if (question.type === "rating") {
            question.answer = data.answers[index].toString();
          } else {
            question.answer = data.answers[index];
          }
        } else {
          question.answer = question.type === "rating" ? "0" : " ";
        }
        updatedFeedback.answers[index] = {
          questionId: question.id,
          answer: question.answer,
        };
      });
      mutationAnswer.mutate(updatedFeedback);
    } else if (feedback.status == "Draft:ask") {
      data.questions.map((question, index) => {
        updatedFeedback.questions[index] = {
          question: question.question,
          type: question.type,
        };
      });
      mutationDraftUpdate.mutate(updatedFeedback);
    } else if (feedback.status == "Draft:give") {
      data.remarks.map((remark, index) => {
        updatedFeedback.remarks[index] = remark;
      });

      mutationDraftUpdate.mutate(updatedFeedback);
    }
  };

  const handleChangeRating = (newRating, index) => {
    setValue(`answers.${index}`, newRating);
  };

  const setAnswer = (value, index) => {
    setValue(`answers.${index}`, value);
  };

  const {
    fields: remarkField,
    append: appendRemark,
    remove: removeRemark,
  } = useFieldArray({
    control,
    name: "remarks",
  });
  const setRemark = (value, index) => {
    setValue(`remarks.${index}`, value);
  };

  const {
    fields: questionField,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "questions",
  });

  const setQuestion = (value, index) => {
    setValue(`questions.${index}.question`, value);
  };
  const setQuestionType = (value, index) => {
    setValue(`questions.${index}.type`, value.title);
  };

  useEffect(() => {
    setIsEdit(false);
  }, [feedback]);

  return (
    <div className={`flex flex-col py-6  h-100 px-6`}>
      {/*--- HEADER START ---*/}
      <div className="lg:flex lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {feedback.subject}
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              {(feedback.status == "Received" ||
                feedback.status == "Draft:ask" ||
                feedback.status == "Requested") &&
                `From  ${feedback.writer.firstName} ${feedback.writer.lastName}`}
              {(feedback.status == "Awaiting Response" ||
                feedback.status == "Draft:give" ||
                feedback.status == "Given") &&
                `To  ${feedback.receiver.firstName} ${feedback.receiver.lastName}`}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              {renderFeedbackBadge(feedback.feedbackStatus, feedback, user)}
            </div>
          </div>
          <div className="mt-2 ml-0 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Updated : {new Date(feedback.updatedAt).toUTCString()}
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4 self-start">
          <span className="hidden sm:block">
            <button
              onClick={onClose}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <XIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Close
            </button>
          </span>
          {feedback.feedbackStatus == "DRAFT" && !isEdit && (
            <span className="sm:ml-3">
              <button
                onClick={() => setIsEdit(true)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                data-testid="updatefeedback"
              >
                <PencilIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                Edit
              </button>
            </span>
          )}
          {feedback.status == "Awaiting Response" && !isEdit && (
            <span className="sm:ml-3">
              <button
                onClick={() => setIsEdit(true)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                Respond
              </button>
            </span>
          )}
          {(feedback.status == "Awaiting Response" ||
            feedback.feedbackStatus == "DRAFT") &&
            isEdit && (
              <span className="sm:ml-3">
                <button
                  onClick={handleSubmit(onSubmit)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  Send
                </button>
              </span>
            )}
        </div>
      </div>
      {/*--- HEADER END ---
            
            
            --- FEEDBACK START ---*/}
      <div className="py-5">
        <ul className="space-y-3">
          {!(feedback.feedbackStatus == "DRAFT" && isEdit) &&
            feedback.questions.map((question, index) => {
              if (question.type === "rating") {
                return (
                  <li
                    key={index}
                    className="bg-white shadow overflow-hidden rounded-md px-6 py-4 items-center"
                  >
                    <div className="">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question,
                        }}
                      />
                    </div>
                    <div className="flex py-4">
                      {isEdit && (
                        <Rating
                          onChange={(e) => handleChangeRating(e, index)}
                          value={0}
                          isEdit={isEdit}
                        />
                      )}
                      {!isEdit && (
                        <Rating
                          isEdit={isEdit}
                          value={parseFloat(question.answer?.answer)}
                        />
                      )}
                    </div>
                  </li>
                );
              }
              if (question.type === "text") {
                return (
                  <li
                    key={index}
                    className="bg-white shadow rounded-md px-6 py-4 justify-aorund"
                  >
                    <div className="pr-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question,
                        }}
                      />
                    </div>
                    <div className="grow flex items-center py-4 justify-between">
                      {!isEdit && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.answer?.answer,
                          }}
                        />
                      )}
                      {isEdit && feedback.status == "Awaiting Response" && (
                        <RichEditor
                          compact
                          onChange={(e) => setAnswer(e, index)}
                          className="w-full min-h-[10rem]"
                          placeholder="Compose your feedback here..."
                        />
                      )}
                    </div>
                  </li>
                );
              }
            })}
          {!(feedback.feedbackStatus == "DRAFT" && isEdit) &&
            feedback.remarks.map((remark, index) => {
              return (
                <li
                  key={index}
                  className="bg-white shadow overflow-hidden rounded-md px-6 py-4 flex items-center"
                >
                  <div className="flex justify-center">
                    <div dangerouslySetInnerHTML={{ __html: remark.remark }} />
                  </div>
                </li>
              );
            })}

          {isEdit && feedback.status == "Draft:ask" && (
            <>
              {questionField.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="bg-white shadow  rounded-md px-6 py-4"
                  >
                    <div className="flex items-center justify-between">
                      <div className=" font-semibold my-4 flex items-center ">
                        <p className="mr-6">Question</p>
                        <SelectWithSupportedText
                          onChange={(e) => setQuestionType(e, index)}
                          options={[
                            { title: "text", description: "A long text." },
                            {
                              title: "rating",
                              description: "Rating on a scale of 1-5",
                            },
                          ]}
                          value={watch(`questions.${index}.type`)}
                        />
                      </div>

                      <TrashIcon
                        onClick={() => removeQuestion(index)}
                        className="text-red-700 h-5 cursor-pointer"
                      />
                    </div>
                    <div className="grow flex items-center justify-around">
                      <RichEditor
                        onChange={(e) => setQuestion(e, index)}
                        className="w-full min-h-[10rem] "
                        compact
                      />
                    </div>
                  </li>
                );
              })}
              <button
                onClick={() =>
                  appendQuestion([{ question: "Question", type: "text" }])
                }
                type="button"
                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <PlusSmIcon
                  className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Add Question</span>
              </button>
            </>
          )}
          {isEdit && feedback.status == "Draft:give" && (
            <>
              {remarkField.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="bg-white shadow overflow-hidden rounded-md px-6 py-4 flex items-center"
                  >
                    <div className="grow flex items-center justify-around">
                      <ReactQuill
                        compact
                        content={watch(`remarks.${index}`)}
                        onChange={(e) => setRemark(e, index)}
                        className="w-11/12 min-h-[10rem] "
                      />
                      <TrashIcon
                        onClick={() => removeRemark(index)}
                        className="text-red-700 h-5 cursor-pointer"
                      />
                    </div>
                  </li>
                );
              })}
              <button
                onClick={() => appendRemark(["Remark"])}
                type="button"
                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <PlusSmIcon
                  className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Add Remark</span>
              </button>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FeedbackDetails;
FeedbackDetails.propTypes = {
  onClose: PropTypes.func,
  feedback: PropTypes.object,
  compact: PropTypes.bool,
  toggleCompact: PropTypes.func,
};
