import O3Prepare from "./O3Prepare";
import FeedbacksToGive from "./FeedbacksToGive";
import NeedMoreAttention from "./NeedMoreAttention";
import PropTypes from "prop-types";
import {
  ShieldExclamationIcon,
  ThumbUpIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import useManagedTeamQuery from "./hooks/useManagedTeamQuery";
import useFeedbacksQuery from "./hooks/useFeedbacksQuery";
import useSkippedMeetingsQuery from "./hooks/useSkippedMeetingsQuery";

const Dashboard = ({ currentUser }) => {
  const currentUserId = currentUser?.user?.id;
  const fetchedManagedTeam = useManagedTeamQuery(currentUserId);

  const fetchedFeedbacks = useFeedbacksQuery();

  const filterFeedbacks = (feedbacks) => {
    return feedbacks?.filter(
      (feedback) =>
        feedback.feedbackStatus === "PENDING" &&
        feedback.writer.email === currentUser?.user?.email
    );
  };

  const filtredFeedbacks = filterFeedbacks(fetchedFeedbacks?.data?.data);

  const fetchedMeetings = useSkippedMeetingsQuery();

  const cards = [
    {
      name: "Managed People",
      href: "/app/meeting",
      icon: UserGroupIcon,
      value: fetchedManagedTeam?.data?.data.length,
    },
    {
      name: "Feedbacks for your Teams",
      href: "feedback",
      icon: ThumbUpIcon,
      value: filtredFeedbacks?.length,
    },
    {
      name: "O3 meetings skipped",
      href: "#",
      icon: ShieldExclamationIcon,
      value: fetchedMeetings?.data?.data.length,
    },
  ];
  return (
    <>
      <div className="mt-8 mb-10 w-full">
        <div className="max-w-full ">
          <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-5">
            {/* Card */}
            {cards.map(({ icon: Icon, name, value, href }) => (
              <div
                key={name}
                className="bg-white overflow-hidden shadow rounded-lg"
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Icon
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dd>
                          <div className="text-3xl text-center font-medium text-gray-900">
                            {value}
                          </div>
                        </dd>
                        <dt className="text-base text-center font-medium text-gray-500 truncate">
                          {name}
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-5 py-3">
                  <div className="text-sm">
                    <Link
                      to={href}
                      className="font-medium text-brand-700 hover:text-brand-900"
                    >
                      View all
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-5">
            <O3Prepare currentUser={currentUser} />
            <FeedbacksToGive feedbacks={filtredFeedbacks} />
            <NeedMoreAttention
              meetings={fetchedMeetings?.data?.data}
              currentUser={currentUser}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  currentUser: PropTypes.object,
};

export default Dashboard;
