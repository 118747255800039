import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {cn} from "utils/utils";

export default function AlertMessage({
  type = "success",
  title,
  message,
  link = "/",
}) {
  const bgColor = type === "success" ? "bg-emerald-400" : "bg-red-400";
  const textColor = type === "success" ? "text-emerald-50" : "text-red-50";
  const btnColors =
    type === "success"
      ? "bg-emerald-500 hover:bg-emerald-300 focus:ring-offset-emerald-600"
      : "bg-red-500 hover:bg-red-300 focus:ring-offset-red-600";
  return (
    <div className="relative">
      <div className="mx-auto max-w-md px-0 sm:max-w-3xl lg:max-w-7xl ">
        <div
          className={cn(
            "relative overflow-hidden rounded-2xl px-6 py-10 shadow-xl sm:px-12 sm:py-20",
            bgColor
          )}
        >
          <div className="relative text-center ">
            <div className="lg:col-span-2">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {title}
              </h2>
              <p className={cn("mx-auto mt-6 max-w-2xl text-lg", textColor)}>
                {message}
              </p>
            </div>
            <div className="mt-12 sm:mt-10">
              <Link
                to={link}
                className={cn(
                  "rounded-md no-underline border border-transparent px-5 py-3 text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2, sm:px-10",
                  btnColors
                )}
              >
                <span>{type === "success" ? "Ok" : "Retry Later"}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AlertMessage.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string,
};
