import React, {useEffect, useState} from "react";
import Avatar from "react-avatar";
import PropTypes from "prop-types";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ConfirmModal from "components/Modals/ConfirmModal";
import {useAxios} from "hooks/useAxios";

const UserDetails2 = ({ user, userId }) => {
  //SUSPENSION
  const [confirmOpen, setConfirmOpen] = useState(false);
  const SuspendMutaion = useMutation(
    async (userId) => {
      return axios.put(`/v1/organizations/suspended-members/${userId}`);
    },
    {
      onSuccess: () => {
        window.location.href = "/app";
      },
    }
  );
  const confirmSuspend = (userId) => {
    SuspendMutaion.mutate(userId);
  };

  //FETCH USER
  const queryClient = useQueryClient();
  const [userProfile, setUserProfile] = useState({});
  const { axios } = useAxios();
  const fetchUserInfo = async () => {
    return axios.get(`/v1/organizations/members/${userId}`);
  };
  // eslint-disable-next-line no-unused-vars
  const profileQuery = useQuery(["profile", user, userId], fetchUserInfo, {
    enabled: !!user?.user?.id,
    onSuccess: (res) => setUserProfile(res.data),
  });
  useEffect(() => {
    queryClient.invalidateQueries(["profile"]);
  }, [userId]);

  return (
    <>
      <ConfirmModal
        open={confirmOpen}
        action={() => confirmSuspend(userId)}
        setOpen={setConfirmOpen}
        title="Deactivate account"
        body="Are you sure you want to deactivate this account? 
        All data will be permanently removed from our servers forever. 
        This action cannot be undone."
      />
      <div className="min-h-full  mt-8">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <Avatar
                  name={`${userProfile.user?.firstName} ${userProfile.user?.lastName}`}
                  className="rounded-full"
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {`${userProfile?.user?.lastName} , ${userProfile?.user?.firstName}`}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Joined{" "}
                <span className="font-bold">
                  {" "}
                  {userProfile.organization?.contactName}
                </span>{" "}
                on <time dateTime="2020-08-25">August 25, 2020</time>
              </p>
            </div>
          </div>
          {user?.organizationMembers?.role === "ADMIN" && (
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                onClick={() => {
                  setConfirmOpen(true);
                }}
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-500"
              >
                Suspend Account
              </button>
            </div>
          )}
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-3">
            {/* Description list*/}

            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6 flex justify-between">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Personal Information
                </h2>

                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {`${userProfile.user?.email} `}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Short Code
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userProfile?.shortCode && `${userProfile?.shortCode} `}
                      {!userProfile?.shortCode && "add a short Code"}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Full name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {`${userProfile.user?.firstName} ${userProfile.user?.lastName}`}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Discord Username
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {userProfile?.discordUsername &&
                        `${userProfile?.discordUsername} `}
                      {!userProfile?.discordUsername &&
                        "add a discord username"}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Position
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userProfile?.position}
                      {!userProfile?.position && "select your position"}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Phone</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {`${userProfile.user?.phone}`}
                      {!userProfile.user?.phone && "add your phone number"}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Manager
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userProfile?.manager &&
                        `${userProfile?.manager?.firstName} ${userProfile?.manager?.lastName}`}

                      {!userProfile?.manager?.lastName && "add your manager"}
                    </dd>
                  </div>

                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Bio</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userProfile.user?.bio}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserDetails2.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
};

export default UserDetails2;
