import {useQuery} from "react-query";
import {useAxios} from "hooks/useAxios";

const ENDPOINT = "/v1/rewards";
export const REWARDS_KEY = "rewards";

export const useGetRewards = () => {
  const { axios } = useAxios();

  return useQuery([REWARDS_KEY],  () => {
    return axios.get(ENDPOINT).then((res) => res.data);
  });
};

export const useGetRewardById = ({rewardId}) => {
  const { axios } = useAxios();

  return useQuery([REWARDS_KEY, rewardId],  () => {
    return axios.get(`${ENDPOINT}/${rewardId}`).then((res) => res.data);
  });
};


export const useGetReports = () => {
  const { axios } = useAxios();

  return useQuery(['/v1/reports'],  () => {
    return axios.get('/v1/reports').then((res) => res.data);
  });
};
