import {React, useEffect, useState} from "react";
import {Combobox, Transition} from "@headlessui/react";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import Avatar from "react-avatar";

export default function SelectManager({
  people = [],
  defaultValue = {},
  control,
  name,
  className,
}) {
  const [selectedPerson, setSelectedPerson] = useState(defaultValue);
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          return (
            person?.user?.firstName?.toLowerCase().includes(query.toLowerCase()) ||
            person?.user?.lastName?.toLowerCase().includes(query.toLowerCase())||
            person?.shortCode?.toLowerCase().includes(query.toLowerCase())
          );
        });

  useEffect(() => {
    if (defaultValue == null) {
      setSelectedPerson(people[0]?.user);
    } else setSelectedPerson(defaultValue);
  }, [people]);

  return (
    <>
      <div className={`flex items-center ${className}`}>
        <div className="w-full max-w-xs ">
          <Controller
            control={control}
            defaultValue={selectedPerson}
            name={name}
            render={({ field: { onChange, value } }) => (
              // <---------- assumption: I would need to pass in onChange here
              <Combobox
                as="div"
                className="space-y-1"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setSelectedPerson(e);
                }} // <----- then probably need to do something with onChange from render props here
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <div className="relative w-full cursor-default overflow-hidden rounded-md border border-gray-300 bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                          displayValue={(person) =>
                            `${person?.firstName} ${person?.lastName}`
                          }
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        show={open}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                        afterLeave={() => setQuery("")}
                      >
                        <Combobox.Options
                          static
                          className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                        >
                          {filteredPeople?.map((person) => (
                            <Combobox.Option
                              key={person?.user?.id}
                              value={person?.user}
                            >
                              {({ selected, active }) => (
                                <div
                                  className={`${
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900"
                                  } cursor-default select-none relative py-2 pl-8 pr-4`}
                                >
                                  <span
                                    className={`${
                                      selected ? "font-semibold" : "font-normal"
                                    } block truncate`}
                                  >
                                    <Avatar
                                      className="h-8 w-8 rounded-full"
                                      alt="Profile image"
                                      name={`${person.user?.firstName} ${person.user?.lastName}`}
                                      size="30"
                                    />{" "}
                                    {`${person?.user?.firstName}  ${person?.user?.lastName}`}
                                  </span>
                                  {selected && (
                                    <span
                                      className={`${
                                        active ? "text-white" : "text-blue-600"
                                      } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Combobox>
            )}
          />
        </div>
      </div>
    </>
  );
}

SelectManager.propTypes = {
  people: PropTypes.array,
  defaultValue: PropTypes.object,
  control: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
};
