const managedTeamKeys = {
  default: ["team"],
  user: (userId) => [...managedTeamKeys.default, userId],
};
const feedabcksKeys = {
  default: ["feedbacks"],
};
const skippedMeetingsKeys = {
  default: ["skippedMeetings"],
};
export { managedTeamKeys, feedabcksKeys, skippedMeetingsKeys };
