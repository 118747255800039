import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {searchUsersKeys} from "./keys";

const ENDPOINT = "/v1/organizations";

const useSearchUsersQuery = (organizationId, searchedValue) => {
  const { axios } = useAxios();

  const searchUsersRequest = async (query) => {
    if (organizationId != undefined)
      return axios.get(`${ENDPOINT}/${organizationId}/search`, {
        params: { query },
      });
  };

  return useQuery(
    searchUsersKeys.user(searchedValue),
    () => searchUsersRequest(searchedValue),
    { keepPreviousData: false }
  );
};

export default useSearchUsersQuery;
