import {React} from "react";
import PropTypes from "prop-types";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {useAuth} from "hooks/useAuth";
import renderFeedbackBadge from "../utils/renderFeedbackBadge";

const FeedbackList = ({ feedbacks, select, selected, filter }) => {
  const { user } = useAuth();

  const setSideView = (id) => {
    //Workaround for bug: Rating component doesn't update when changing selected feedback
    //So force rerender

    select("");
    setTimeout(() => {
      select(id);
    }, 0);
    // select(id);
  };

  const filterFeedbacks = (feedbacks, filter, user) => {
    if (filter == "All Feedbacks") return feedbacks;
    else if (filter == "My Requests")
      return feedbacks.filter(
        (feedback) =>
          feedback.feedbackStatus == "PENDING" &&
          feedback.receiver.email == user.user.email
      );
    else if (filter == "Received")
      return feedbacks.filter(
        (feedback) =>
          feedback.feedbackStatus == "COMPLETED" &&
          feedback.receiver.email == user.user?.email
      );
    else if (filter == "Given")
      return feedbacks.filter(
        (feedback) =>
          feedback.feedbackStatus == "COMPLETED" &&
          feedback.writer.email == user.user?.email
      );
    else if (filter == "Awaiting My Response")
      return feedbacks.filter(
        (feedback) =>
          feedback.feedbackStatus == "PENDING" &&
          feedback.writer.email == user.user.email
      );
    else if (filter == "Drafts")
      return feedbacks.filter((feedback) => feedback.feedbackStatus == "DRAFT");
    else return feedbacks;
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date Updated
                  </th>
                  <th
                    scope="col"
                    className=" py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filterFeedbacks(feedbacks, filter, user)?.map((feedback) => {
                  return (
                    <tr
                      key={feedback.id}
                      onClick={() => setSideView(feedback.id)}
                      className={selected === feedback.id ? "bg-slate-100" : ""}
                    >
                      <td className={`px-6 py-4 whitespace-nowrap `}>
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {feedback.subject}
                            </div>
                            <div className=" text-gray-500 text-xs">
                              {(feedback.status == "Received" ||
                                feedback.status == "Requested") &&
                                `From  ${feedback.writer.firstName} ${feedback.writer.lastName}`}
                              {(feedback.status == "Awaiting Response" ||
                                feedback.status == "Given") &&
                                `To  ${feedback.receiver.firstName} ${feedback.receiver.lastName}`}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {renderFeedbackBadge(
                            feedback.feedbackStatus,
                            feedback,
                            user
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap ">
                        <div className="flex items-center">
                          {new Date(feedback.updatedAt).toUTCString()}
                        </div>
                      </td>
                      <td>
                        <div className="position-relative">
                          <DotsVerticalIcon className="h-5 text-gray-400 cursor-pointer" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
FeedbackList.propTypes = {
  feedbacks: PropTypes.array,
  select: PropTypes.func,
  selected: PropTypes.string,
  filter: PropTypes.string,
};

export default FeedbackList;
