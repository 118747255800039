import {useState} from "react";
import PropTypes from "prop-types";
import {SearchIcon} from "@heroicons/react/solid";
import {useClickOutside} from "../../hooks/useClickOutside";
import SearchResults from "./SearchResults";
import useSearchUsersQuery from "./hooks/useSearchUsersQuery";

export default function SearchUsers({ organizationId }) {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const searchUsersResult = useSearchUsersQuery(organizationId, value);

  let domNode = useClickOutside(() => {
    setValue("");
  });
  
  return (
    <div
      className="flex-1 flex justify-center lg:justify-end z-[120]"
      ref={domNode}
    >
      <div className="relative w-8/12 px-2 lg:px-6">
        <label htmlFor="search" className="sr-only">
          Search users
        </label>
        <div className="relative text-brand-200 focus-within:text-gray-400">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-brand-400 bg-opacity-25 text-brand-100 placeholder-brand-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
            placeholder="Search users"
            type="search"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {value?.length > 0 && (
          <SearchResults
            isLoading={searchUsersResult.isLoading}
            users={searchUsersResult?.data?.data || []}
          />
        )}
      </div>
    </div>
  );
}
SearchUsers.propTypes = {
  organizationId: PropTypes.string,
};
