import PropTypes from "prop-types"

export default function TextAreaSimple({label,register, name, className}) {
    return (
      <div className={className}>
        <label htmlFor="textArea" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-3">
          <textarea
            {...register(name)}
            rows={4}
            name={name}
            className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
            defaultValue={''}
          />
        </div>
      </div>
    )
  }
  
TextAreaSimple.propTypes = {
    register: PropTypes.any,
    name:PropTypes.string,
    label: PropTypes.string,
    className:PropTypes.string,
  };