import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Note from "../Notes";
import {
  CalendarIcon as CalendarIconOutline,
  PlusSmIcon,
} from "@heroicons/react/outline";
import EmptyState from "components/Empty-state";
import Spinner from "components/Spinner";
import PrivateNote from "../PrivateNote";
import NextNote from "../NextNote";
import Modal from "components/Modals";
import CreateEventForm from "../CreateEventForm";
import useNotesQueries from "../hooks/useNotesQueries";
import Checklist from "./Checklist";

const Meeting = ({ currentMeeting, member, email }) => {
  const [checklistVisible, setChecklistVisible] = useState(false);
  const { createNotemutatation, plannedNotesFetched, nonePlannedNotesFetched } =
    useNotesQueries({}, currentMeeting);

  const nonePlannedNotes = nonePlannedNotesFetched?.data?.data;

  const plannedNotes = plannedNotesFetched?.data?.data;

  const [notesPlanned, setNotesPlanned] = useState(plannedNotes);
  const [notesNonePlanned, setNotesNonePlanned] = useState(nonePlannedNotes);

  useEffect(() => {
    setNotesPlanned(plannedNotes);
    setNotesNonePlanned(nonePlannedNotes);
  }, [plannedNotes, nonePlannedNotes]);

  const getPrivateNote = (privateNotes) => {
    const filtredData = privateNotes?.filter(
      (privateNote) => privateNote.owner.email == email
    );
    return filtredData ? filtredData[0] : null;
  };
  const [addEvent, setAddEvent] = useState(false);
  let [isFormOpen, setIsFormOpen] = useState(false);

  function closeModal() {
    setIsFormOpen(false);
  }
  function openModal() {
    setIsFormOpen(true);
  }

  function toggleChecklist(value, index) {
    if(index===0)
      setChecklistVisible(value);
  }
  
  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between pb-4">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {member}
          </h2>
          {notesPlanned?.length > 0 &&
            notesPlanned?.map((note) => (
              <NextNote
                key={note.id}
                note={note}
                currentMeeting={currentMeeting}
                nonePlannedNotes={nonePlannedNotes}
              />
            ))}
        </div>
      </div>
      <div className="relative mb-5">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300 " />
        </div>
        <div className="relative flex justify-center">
          <button
            type="button"
            onClick={() => {
              setAddEvent(true);
              openModal();
            }}
            className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            <PlusSmIcon
              className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>Schedule new meeting</span>
          </button>
        </div>
      </div>

      {createNotemutatation.isLoading && <Spinner />}
      {notesPlanned?.length == 0 && notesNonePlanned?.length == 0 && (
        <EmptyState
          onCreateNew={() => {
            setAddEvent(true);
            openModal();
          }}
          btnText={"Plan new O3 meeting"}
          paragraph={"Plan your first new O3 meeting"}
          Icon={CalendarIconOutline}
          currentMeeting={currentMeeting}
          member={member}
        />
      )}
      {addEvent && (
        <>
          <Modal isOpen={isFormOpen} onClose={closeModal} title={"Schedule new meeting"}>
            <CreateEventForm
              closeModal={closeModal}
              setAddEvent={setAddEvent}
              currentMeeting={currentMeeting}
              onCreateNewNote={createNotemutatation.mutate}
            />
          </Modal>
        </>
      )}
      {notesNonePlanned?.length > 0 && (
        <div className="px-4 py-4 sm:px-6">
          <h3 className="text-2xl leading-6 font-medium text-gray-900">
            History
          </h3>
        </div>
      )}
      {notesNonePlanned
        ?.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .map((e, index) => (
          <div className="flex flex-col md:flex-row space-x-5 overflow-auto" key={e.id}>
            <div className="md:basis-3/4">
              <Note
                index={index}
                key={e.id}
                note={e}
                currentMeeting={currentMeeting}
                onCollapse={toggleChecklist}
                defaultCollapsed={checklistVisible && index===0}
              />
            </div>
            <div className="md:basis-1/4">
              <PrivateNote
                key={e?.privateNotes?.id}
                privateNote={getPrivateNote(e?.privateNotes)}
              />
              {index == 0 && checklistVisible && <Checklist />}
            </div>
          </div>
        ))}
    </>
  );
};

export default Meeting;

Meeting.propTypes = {
  member: PropTypes.string,
  currentMeeting: PropTypes.object,
  email: PropTypes.string,
};
