import {CheckCircleIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import Avatar from "react-avatar";
import {MoodSelector} from "components/Forms/MoodSelector";

export default function Header({
  startTime,
  currentMeeting,
  setCollapsed,
  collapsed,
  onSelectMood,
  selectedMood,
}) {
  return (
    <div className="lg:flex lg:justify-between pb-4">
      <div className="flex-1 min-w-0">
        <div className="relative flex items-center gap-5 h-12 transition-all ease duration-300">
          <h4 className="hover:cursor-pointer text-xl font-bold leading-7 text-gray-900 sm:truncate ">
            {new Date(startTime).toLocaleString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}
          </h4>
          <MoodSelector
            selectedMood={selectedMood}
            onSelectMood={onSelectMood}
          />
        </div>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
            <CheckCircleIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {!startTime && (
              <p className="hover:text-violet-500 hover:font-bold bg-slate-300 py-1 px-2 rounded-lg">
                No event
              </p>
            )}

            {startTime && (
              <p className="hover:text-brand-700 hover:font-semibold underline hover:underline-offset-1">
                {new Date(startTime).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                })}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <Avatar
          name={`${currentMeeting.owner.firstName} ${currentMeeting.owner.lastName}  `}
          size="40"
          className="h-10 w-10 rounded-full ml-3 text-semibold"
        />
        <Avatar
          name={`${currentMeeting.participant.firstName} ${currentMeeting.participant.lastName}  `}
          size="40"
          className="h-10 w-10 rounded-full text-semibold"
        />
      </div>
      {!collapsed && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 m-2  cursor-pointer text-gray-400 hover:text-brand-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
          />
        </svg>
      )}
      {collapsed && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 m-2  cursor-pointer text-gray-400 hover:text-brand-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 11l7-7 7 7M5 19l7-7 7 7"
          />
        </svg>
      )}
      <div className="flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
    </div>
  );
}

Header.propTypes = {
  currentMeeting: PropTypes.object,
  startTime: PropTypes.string,
  setCollapsed: PropTypes.func,
  collapsed: PropTypes.bool,
  onSelectMood: PropTypes.func,
  selectedMood: PropTypes.number,
};
