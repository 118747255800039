import {useQuery} from "react-query";
import {useAxios} from "hooks/useAxios";

const ENDPOINT = "/v1/reports";
export const REPORTS_KEY = "reports";

export const useGetReports = () => {
  const { axios } = useAxios();

  return useQuery([REPORTS_KEY],  () => {
    return axios.get(ENDPOINT).then((res) => res.data);
  });
};

