import Avatar from "react-avatar";
import PropTypes from "prop-types";

export default function Feed({ list, userEmail }) {
  return (
    <div className="flow-root mt-6">
      <ul role="list" className="-my-5 divide-y divide-gray-200">
        {list?.map((item) => (
          <li key={item?.id} className="py-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Avatar
                  name={
                    item.participant.email == userEmail
                      ? `${item?.owner?.firstName} ${item.owner.lastName}`
                      : `${item?.participant?.firstName} ${item.participant.lastName}`
                  }
                  size="40"
                  round={true}
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {item.participant.email == userEmail
                    ? `${item?.owner?.firstName} ${item.owner.lastName}`
                    : `${item?.participant?.firstName} ${item.participant.lastName}`}{" "}
                </p>
                <p className="text-sm text-gray-500 truncate">
                  {new Date(item?.createdAt).toUTCString()}
                </p>
              </div>
              <div>
                <a
                  href="#"
                  className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                >
                  View
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
Feed.propTypes = {
  list: PropTypes.array,
  userEmail: PropTypes.string,
};
