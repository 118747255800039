import {React, useState} from "react";
import Sidebar from "components/Nav/Sidebar";
import {navigation} from "./utils/contants";
import FeedbackList from "./FeedbackList";
import AddButton from "components/octo-ui/Button/AddButton";
import FeedbackDetails from "./FeedbackDetails";
import FeedbackGiveForm from "./FeedbackGiveForm";
import FeedbackAskForm from "./FeedbackAskForm";
import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {useAuth} from "hooks/useAuth";
import Spinner from "components/Spinner";
import SuccessAlert from "components/Alerts/SuccessAlert";

const Feedback = () => {
  const { user } = useAuth();
  const { axios } = useAxios();

  const [sideView, setSideView] = useState("");

  const fetchFeedbacks = async () => {
    return axios.get(`/v1/feedbacks`);
  };

  const fetchedFeedbacks = useQuery(["feedbacks", user], fetchFeedbacks, {
    enabled: !!user?.user?.id,
  });

  const fetchMembers = async () => {
    return await axios.get(
      `/v1/organizations/${user?.organization?.id}/members`
    );
  };
  const fetchedMembers = useQuery(["members", user], fetchMembers, {
    enabled: !!user?.organization,
  });

  const renderSideView = (param) => {
    switch (param) {
      case "":
        return null;
      case "ask":
        return (
          <FeedbackAskForm
            members={
              fetchedMembers?.data?.data?.filter(
                (x) => x?.user.email !== user?.user?.email
              ) || []
            }
            user={user}
            onClose={() => setSideView("")}
            setDispledAlert={displayAlert}
          />
        );
      case "give":
        return (
          <FeedbackGiveForm
            members={
              fetchedMembers?.data?.data?.filter(
                (x) => x?.user.email !== user?.user?.email
              ) || []
            }
            onClose={() => setSideView("")}
            user={user}
            setDispledAlert={displayAlert}
          />
        );
      default:
        return (
          <FeedbackDetails
            onClose={() => setSideView("")}
            feedback={fetchedFeedbacks.data?.data?.find((x) => x.id === sideView)}
          />
        );
    }
  };

  const [current, setCurrent] = useState("all");

  const [createOpen, setCreateOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  const displayAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  return (
    <div className="pt-16 flex flex-col">
      <Sidebar
        navigation={navigation}
        current={current}
        setCurrent={setCurrent}
      />
      <main className="md:pl-64 flex grow">
        {alert && (
          <SuccessAlert
            message="Feedback sended successfully"
            position="absolute top-10 "
          />
        )}
        <div className={`py-6 grow min-h-[92.5vh]`}>
          {renderSideView(sideView) === null && (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between">
              <h1 className=" text-2xl font-semibold text-gray-900">
                {navigation.find((x) => x.id === current).name}
              </h1>
              <div className="relative overflow-visible">
                <AddButton
                  onClick={() => setCreateOpen(!createOpen)}
                  buttonText="Create"
                />
                {createOpen && (
                  <div className="absolute right-0 w-max mt-2 rounded-md drop-shadow-xl overflow-hidden bg-slate-100">
                    <ul>
                      <li
                        className="hover:bg-brand-500 cursor-pointer  px-5  py-2 hover:text-white"
                        onClick={() => {
                          setCreateOpen(false);
                          setSideView("ask");
                        }}
                      >
                        Ask for feedback
                      </li>
                      <li
                        className="hover:bg-brand-500 cursor-pointer  px-5  py-2 hover:text-white"
                        onClick={() => {
                          setCreateOpen(false);
                          setSideView("give");
                        }}
                      >
                        Give feedback
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              {fetchedFeedbacks.isLoading && <Spinner />}
              {fetchedFeedbacks.isSuccess &&
              renderSideView(sideView) === null ? (
                <FeedbackList
                  filter={current}
                  feedbacks={fetchedFeedbacks?.data?.data}
                  select={setSideView}
                  selected={sideView}
                />
              ) : (
                renderSideView(sideView)
              )}
            </div>
          </div>
        </div>

        {/* {renderSideView(sideView)} */}
      </main>
    </div>
  );
};

export default Feedback;
