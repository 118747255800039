import React, {useEffect, useState} from "react";
import UserList from "./UserList";
import Modal from "components/Modals";
import {useAxios} from "hooks/useAxios";
import SuccessAlert from "components/Alerts/SuccessAlert";
import {useQuery} from "react-query";
import {useAuth} from "hooks/useAuth";
import InviteesList from "./InviteesList";
import InviteUsersForm from "./InviteUsersForm";
import {teamPageTabs} from "./utils/tabs";
import AddButton from "../Events/NextNote/AddButton";

const Team = () => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { user } = useAuth();
  const [alert, setAlert] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [team, setTeam] = useState([]);


  const fetchInvites = async () => {
    if (user.organization)
      return await axios.get(
        `/v1/organizations/${user.organization?.id}/invitations`
      );
  };
  const invited = useQuery(["fetchInvites", user], fetchInvites, {
    enabled: !!user,
  });

  const [currentTab, setCurrentTab] = useState("Team Members");
  const toggleTab = (tab) => {
    setCurrentTab(tab);
  };

  const { axios } = useAxios();

  const fetchMembers = async () => {
    if (user?.organization)
      return axios.get(`/v1/organizations/${user.organization?.id}/members`);
  };

  const { data, isLoading } = useQuery(["fetchmembers", user], fetchMembers, {
    enabled: true,
    onSuccess: (res) => {
      if (res != undefined) setTeam(res.data);
    },
  });

  useEffect(() => {
    if (user?.organizationMembers?.role === "ADMIN") {
      setIsAdmin(true);
    }
    if (data === undefined) {
      return;
    }

    setTeam(data?.data);
  }, [user]);

  const displayAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };
  function closeModal() {
    setIsInviteOpen(false);
  }

  function openModal() {
    setIsInviteOpen(true);
  }

  return (
    <div className="flex flex-col items-center w-full pt-16">
      {alert && (
        <SuccessAlert
          message="Invitations sent successfully"
          position="absolute top-20 "
        />
      )}
      {isInviteOpen ? (
        <Modal
          isOpen={isInviteOpen}
          onClose={closeModal}
          title="Invite Members"
        >
          <InviteUsersForm
            setClose={setIsInviteOpen}
            SetdisplayAlert={displayAlert}
          />
        </Modal>
      ) : null}

      <div className="container flex justify-between items-center px-2">
        <h1 className="text-2xl font-semibold text-gray-900 my-10">
          Organization Members
        </h1>
        {isAdmin ? (
          <AddButton onClick={openModal} buttonText="Invite Users" />
        ) : null}
      </div>
      {/* TABS NAVIGATION */}
      <div className="container flex justify-between items-center px-2 my-2">
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {teamPageTabs.map((tab) => (
                <a
                  key={tab.name}
                  className={`${
                    tab.name == currentTab
                      ? "border-brand-500 text-brand-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }
                    group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer
                `}
                  onClick={() => toggleTab(tab.name)}
                >
                  <tab.icon
                    className={`${
                      tab.name == currentTab
                        ? "text-brand-500"
                        : "text-gray-400 group-hover:text-gray-500"
                    }
                    -ml-0.5 mr-2 h-5 w-5
                  `}
                    aria-hidden="true"
                  />
                  <span>{tab.name}(<span className="text-xs">{tab.name=="Invited"? invited?.data?.data.length : team.length}</span>) </span>
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {/* END TABS NAVIGATION */}

      <div className="container mx-auto">
        {currentTab == "Team Members" && (
          <UserList
            users={team}
            isLoading={isLoading}
            role={user?.organizationMembers?.role}
          />
        )}

        {currentTab == "Invited" && <InviteesList user={user} invited={invited?.data?.data} />}
      </div>
    </div>
  );
};

export default Team;
