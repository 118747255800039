import {MenuIcon} from "@heroicons/react/outline";

import {useState} from "react";
import {useQuery} from "react-query";
import SideBar from "./SideBar";
import {useAuth} from "hooks/useAuth";
import {useAxios} from "hooks/useAxios";
import Meeting from "./Meeting";
import O3Prepare from "../Dashboard/O3Prepare";
import NeedMoreAttention from "../Dashboard/NeedMoreAttention";

export default function Events() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [member, setMember] = useState("");
  const [meeting, setMetting] = useState(null);
  const { axios } = useAxios();
  const { user } = useAuth();

  let id = user?.user?.id;
  const fetchMembers = async () => {
    return axios.get(`/v1/organizations/teams/${user.user.id}`);
  };

  const mutation = useQuery(["fetchMembers", id], fetchMembers, {
    enabled: !!id,
  });

  return (
    <div className="flex z-0 pt-16">
      <SideBar
        team={mutation.data?.data}
        setMember={setMember}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        setMeeting={setMetting}
        manager={user?.organizationMembers?.manager}
        currentUser={user?.user?.email}
      />

      <div className="flex flex-col min-w-0 flex-1 ">
        {/** navbar for mobile version  */}
        <div className="lg:hidden ">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {/** */}
        <div className="flex-1 relative   overflow-hidden">
          <main className="flex-1 relative  overflow-y-auto  ">
            {/* Start main area*/}

            <div className="">
              <div className="min-h-screen border-2 border-gray-200 px-2 py-10 md:px-10 border-none rounded-lg">
                {!meeting && (
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-5">
                    <O3Prepare currentUser={user} />
                    <NeedMoreAttention currentUser={user} />
                  </div>
                )}

                {meeting && (
                  <Meeting
                    currentMeeting={meeting}
                    member={member}
                    email={user?.user?.email}
                  />
                )}
              </div>
            </div>

            {/* End main area */}
          </main>
        </div>
      </div>
    </div>
  );
}
