import Avatar from "react-avatar";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CollectionIcon} from "@heroicons/react/outline";

export default function FeedbacksToGive({ feedbacks }) {
  return (
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2
          className="text-base font-medium text-gray-900"
          id="recent-hires-title"
        >
          FeedBacks to give
        </h2>
        <div className="flow-root mt-6">
          {feedbacks?.length === 0 ? (
            <div className="flex justify-center items-center">
              <CollectionIcon className="text-gray-200  h-12 w-12 my-4" />
            </div>
          ) : (
            <ul role="list" className="-my-5 divide-y divide-gray-200">
              {feedbacks?.map((feedback) => (
                <li key={feedback?.id} className="py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <Avatar
                        name={`${feedback?.receiver?.firstName} ${feedback?.receiver?.lastName}`}
                        size="40"
                        round={true}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {feedback?.receiver?.firstName}{" "}
                        {feedback?.receiver?.lastName}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {new Date(feedback?.createdAt).toUTCString()}
                      </p>
                    </div>
                    <div>
                      <a
                        href="#"
                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                      >
                        View
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {feedbacks?.length > 3 && (
            <div className="mt-6">
              <Link
                to="/app/meeting"
                className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                View all
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
FeedbacksToGive.propTypes = {
  feedbacks: PropTypes.array,
};
