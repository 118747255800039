/* eslint-disable no-unused-vars */
import React from "react";
import {Link} from "react-router-dom";
import {GiftIcon} from "@heroicons/react/outline";
import DoughnutChart from "./DoughnutChart";
import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import PropTypes from "prop-types";

const KudoMetrics = ({ user }) => {
  const { axios } = useAxios();

  //fetch My Kudo transactions
  const fetchMyKudoMetrics = async () => {
    return await axios.get(
      `/v1/kudos/my-metrics/?orgId=${user?.organization?.id}`
    );
  };
  const { data: metrics } = useQuery(
    ["myKudoMetrics", user],
    fetchMyKudoMetrics,
    {
      enabled: !!user.organization,
    }
  );
  //end Fetch My Kudo transactions
  if (!metrics) {
    return null;
  }

  return (
    <div className="rounded-lg bg-white shadow  flex flex-col items-center my-10">
      <div className="flex flex-col justify-between my-4 px-4 w-full">
        <div className="flex items-center justify-between my-4 px-4 w-full">
          <h2 className="text-xl font-semibold">Kudo Recognitions</h2>
          <Link
            to="/app/rewards"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <GiftIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
                data-testid="reward-icon"
              />
             
            View Rewards
          </Link>
        </div>
        <div className="flex justify-evenly my-4">
          <div className="flex flex-col items-center  bg-blue-100 text-blue-700 px-8 py-4 rounded-2xl">
            <p className="font-bold text-3xl">
              {user?.organizationMembers?.kudoBalance}
            </p>
            <p>To Give Away</p>
          </div>
          <div className="flex flex-col items-center bg-amber-100 text-amber-700 px-8 py-4 rounded-2xl">
            <p className="font-bold text-3xl">
              {metrics?.data?.pointsReceived}
            </p>
            <p className="text-center">
              {" "}
              {metrics?.data?.receivedCount + " kudos Received"}{" "}
            </p>
          </div>
          <div className="flex flex-col items-center bg-green-100 text-green-700 px-8 py-4 rounded-2xl">
            <p className="font-bold text-3xl">{metrics?.data?.pointsGiven}</p>
            <p className="text-center">
              {" "}
              {metrics?.data?.givenCount + " kudos Given"}{" "}
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full my-[-2rem]">
          {metrics.tagOccurrences != {} && (
            <div className="w-2/3">
              <DoughnutChart data={metrics?.data?.tags} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KudoMetrics;

KudoMetrics.propTypes = {
  user: PropTypes.object,
};
