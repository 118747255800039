import React from "react";
import {PlusSmIcon, TrashIcon, XIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {ArchiveIcon, CheckIcon} from "@heroicons/react/outline";
import {useFieldArray, useForm} from "react-hook-form";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import RichEditor from "components/RichEditor";
import SelectMultipleUsers from "components/Forms/Select/SelectMultipleUsers";

const FeedbackGiveForm = ({ onClose, members, user, setDispledAlert }) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const createFeedbackApi = async (data) => {
    return axios.post(
      `/v1/feedbacks?feedbackStatus=${data.feedbackStatus}`,
      data
    );
  };
  const mutation = useMutation(
    (newFeedback) => {
      return createFeedbackApi(newFeedback);
    },
    {
      onSuccess: () => {
        setDispledAlert();
        queryClient.invalidateQueries(["feedbacks"]);
        onClose();
      },
    }
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      remarks: [""],
    },
  });
  const {
    fields: remarkField,
    append: appendRemark,
    remove: removeRemark,
  } = useFieldArray({
    control,
    name: "remarks",
  });

  const setRemark = (value, index) => {
    setValue(`remarks.${index}`, value);
  };
  const getEmails = (writers) => {
    return writers.map((writer) => writer.email);
  };

  const onSubmit = (data) => {
    data.writers = [user.user.email];
    data.feedbackStatus = "Completed";
    data.receivers = getEmails(data.receivers);
    mutation.mutate(data);
  };

  const onDraft = (data) => {
    data.writers = [user.user.email];
    data.feedbackStatus = "Draft";
    data.receivers = getEmails(data.receivers);
    mutation.mutate(data);
  };

  return (
    <div className={`flex flex-col py-6  h-100 px-6`}>
      {/*--- HEADER START ---*/}
      <div className="lg:flex lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            Give feedback
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4 self-start">
          <span className="hidden sm:block">
            <button
              data-testid="discard"
              onClick={onClose}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <XIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Discard
            </button>
          </span>
          <span className="hidden sm:block sm:ml-2">
            <button
              data-testid="draft"
              onClick={handleSubmit(onDraft)}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <ArchiveIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Draft
            </button>
          </span>
          <span className="sm:ml-3">
            <button
              data-testid="send"
              onClick={handleSubmit(onSubmit)}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Send
            </button>
          </span>
        </div>
      </div>
      {/*--- HEADER END ---
    
    
    --- FORM START ---*/}
      <div className="py-5">
        <ul className="space-y-3">
          <li className="bg-white shadow rounded-md px-6 py-4 z-[120]">
            <div className="font-semibold mb-1">To</div>
            <SelectMultipleUsers
              people={members}
              defaultValue={members[0]?.user}
              control={control}
              name={`receivers`}
            />
            <div className="mb-1 mt-2 font-semibold">Subject</div>
            <input
              data-testid="subject"
              {...register("subject", { required: true })}
              type="text" 
              className="mt-1 block  w-full  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
            ></input>
          </li>

          {remarkField.map((item, index) => {
            return (
              <li
                data-testid="remark"
                key={index}
                className="bg-white shadow overflow-hidden rounded-md px-6 py-4"
              >
                <div className="font-semibold mb-4 flex justify-between ">
                  <p>Comment</p>

                  <TrashIcon
                    data-testid={`delete-remark.${index}`}
                    onClick={() => removeRemark(index)}
                    className="text-gray-500 h-5 cursor-pointer"
                  />
                </div>
                <div className="grow flex items-center justify-between">
                  <RichEditor
                    compact
                    onChange={(e) => setRemark(e, index)}
                    className="w-full min-h-[10rem] "
                    placeholder="Compose your feedback comment here"
                  />
                </div>
              </li>
            );
          })}
        </ul>

        <div className="relative mt-3">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              onClick={() => appendRemark([""])}
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <PlusSmIcon
                className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Add Remark</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackGiveForm;

FeedbackGiveForm.propTypes = {
  onClose: PropTypes.func,
  members: PropTypes.array,
  user: PropTypes.object,
  compact: PropTypes.bool,
  toggleCompact: PropTypes.func,
  setDispledAlert: PropTypes.func,
};
