import {Menu, Transition} from "@headlessui/react";
import {
  CalendarIcon,
  ClockIcon,
  DotsVerticalIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {Fragment} from "react";
import {classNames} from "utils/utils";

export default function Header({ note, currentMeeting, deleteNote }) {
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  return (
    <div className="lg:flex lg:items-center lg:justify-between pb-4">
      <div className="flex-1 min-w-0">
        <h2 className="hover:text-brand-500 hover:cursor-pointer text-xl font-bold leading-7 text-gray-800 sm:text-3l sm:truncate ">
          O3 {currentMeeting?.participant?.firstName + " "} /{" "}
          {currentMeeting?.owner?.firstName}
        </h2>
        <div className="mt-1 flex flex-col sm:flex-col sm:flex-wrap sm:mt-0 sm:space-x-0">
          <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
            <CalendarIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {!note?.startTime && (
              <p className="hover:text-violet-500 hover:font-bold bg-slate-300 py-1 px-2 rounded-lg">
                No event
              </p>
            )}

            {note?.startTime && (
              <p className="hover:text-brand-700 hover:font-semibold underline hover:underline-offset-1">
                {new Date(note?.startTime).toUTCString()}
              </p>
            )}
          </div>
          <div className="mt-3 flex items-center text-sm text-gray-500 cursor-pointer">
            {note?.recurrence == "ONE_TIME" ? (
              <ClockIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <RefreshIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}

            {capitalize(
              new String(note?.recurrence).toLowerCase().replaceAll("_", " ", 2)
            )}
          </div>
        </div>
      </div>

      <div className="mt-4 flex lg:mt-0 lg:ml-3">
        <span className="sm:ml-3">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex items-center p-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
              <span data-testid="menu" className="sr-only">
                Open options menu
              </span>
              <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[120] focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => deleteNote()}
                      >
                        Delete meeting
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </span>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4"></div>
      <div className="flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
    </div>
  );
}

Header.propTypes = {
  currentMeeting: PropTypes.object,
  note: PropTypes.object,
  deleteNote: PropTypes.func,
};
