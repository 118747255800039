import {useCallback, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import axios from "axios";
import {API_URL} from "../config/envParams";
import {useQuery} from "react-query";

/**
 * Returns the auth info and some auth strategies.
 *
 */
export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();
  const [error, setError] = useState("");

  const axiosConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  };

  const fetchUserInfo = async () => {
    try {
      return axios.get(`${API_URL}/v1/user-profile`, axiosConfig);
    } catch (err) {
      setError(err);
    }
  };
  const { data } = useQuery(
    ["user-profile", initialized, keycloak],
    fetchUserInfo,
    {
      onError: (err) => {
        setError(err);
      },
      enabled: (initialized && !!keycloak?.token),
    }
  );

  return {
    isAuthenticated: keycloak?.authenticated,
    initialized: initialized,
    error,
    meta: {
      keycloak,
    },
    token: keycloak?.token,
    user: data?.data,
    roles: keycloak.realmAccess,
    login: useCallback(() => {
      keycloak.login({
        idpHint: "google",
      });
    }, [keycloak]),
    logout: useCallback(() => {
      keycloak.logout({
        redirectUri: window.location.origin,
      });
    }, [keycloak]),
    register: useCallback(() => {
      keycloak.register();
    }, [keycloak]),
  };
};

export default {
  useAuth,
};
