import React from "react";
import {PlusIcon} from "@heroicons/react/outline";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";

const AddButton = ({ type, isLoading, buttonText, onClick }) => {
  return (
    <button
      data-testid="btn-add"
      type={type}
      className={`${
        isLoading && "cursor-not-allowed"
      }  flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
      onClick={onClick}
      disabled={isLoading}
    >
      {!isLoading && (
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      )}
      {isLoading && <Spinner height="5" width="5" />}

      {buttonText}
    </button>
  );
};

export default AddButton;

AddButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  isLoading: PropTypes.bool,
};
