import {
  ArchiveIcon,
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  ClockIcon,
  InboxIcon,
  InboxInIcon,
} from "@heroicons/react/outline";

export const navigation = [
  { id: "all", name: "All Feedbacks", href: "#", icon: InboxIcon },
  { id: "my-request", name: "My Requests", href: "#", icon: ClockIcon },
  { id: "received", name: "Received", href: "#", icon: InboxInIcon },
  { id: "awaiting", name: "Awaiting My Response", href: "#", icon: ClipboardCopyIcon },
  { id: "given", name: "Given", href: "#", icon: ClipboardCheckIcon },
  { id: "draft", name: "Drafts", href: "#", icon: ArchiveIcon },
];
