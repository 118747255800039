import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import {Link} from 'react-router-dom'

const KudoTransaction = ({transaction}) => {
    return (
        <li className="mb-10 ml-4 pb-5 border-b" key={transaction.id}>
            <div className="absolute w-3 h-3 bg-gray-300 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{new Date(transaction.createdAt).toUTCString()}</time>
            <div className="my-4  flex justify-between">
                <Link to="#">
                    <div className="mr-4 flex items-center">
                        <Avatar name={`${transaction.sender.firstName} ${transaction.sender.lastName}`} size="30" round />
                        <p className="text-sm ml-2 font-medium text-gray-700 group-hover:text-gray-900">{`${transaction.sender.firstName} ${transaction.sender.lastName}`}</p>
                    </div>
                </Link>
                <div className="flex items-center justify-center relative grow">
                    <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                    >
                        <div className="w-full border-t border-gray-300 arrow" />
                    </div>
                    <div className=" relative bg-amber-100 rounded-xl py-1 px-2 ">
                        <p className="text-amber-700 font-semibold text-center">{transaction.amount} Kudo Points</p>
                    </div>
                </div>
                <Link to="#">
                    <div className="flex ml-4  items-center mr-6">
                        <p className="text-sm mr-2 font-medium text-gray-700 group-hover:text-gray-900">{`${transaction.receiver.firstName} ${transaction.receiver.lastName}`}</p>
                        <Avatar name={`${transaction.receiver.firstName} ${transaction.receiver.lastName}`} size="30" round />
                    </div>
                </Link>
            </div>
            <h5 className="text-gray-700 font-semibold">Message:</h5>
            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{transaction.message}</p>
        </li>
    )
}

export default KudoTransaction

KudoTransaction.propTypes = {
    transaction: PropTypes.object,
}