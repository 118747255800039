import React, {useState} from "react";
import AddButton from "components/octo-ui/Button/AddButton";
import TextAreaSimple from "./../../../components/Forms/Inputs/TextAreaSimple";
import TextWithError from "components/Forms/Inputs/TextWithError";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {useAxios} from "hooks/useAxios";
import Spinner from "components/Spinner";
import DangerAlert from "components/Alerts/DangerAlert";
import EmailInput from "components/Forms/Inputs/EmailInput";

const CreateOrganizationForm = ({ setClose, SetdisplayAlert }) => {
  const [requestedData, setRequestedData] = useState({});

  const { axios } = useAxios();
  const callApi = async () => {
    return axios.post("/v1/organizations", requestedData);
  };
  const {
    isError,
    isLoading,
    mutate: postOrganization,
  } = useMutation(callApi, {
    onSuccess: () => {
      setClose(false);
      SetdisplayAlert();
    },
  });

  // eslint-disable-next-line no-unused-vars
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setRequestedData(data);
    postOrganization();
  };

  return (
    <>
      {isError ? <DangerAlert /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextWithError
          name="name"
          label="Organization Name"
          register={register}
          errors={errors["name"]}
          className="my-10"
          dataTestId="org-name"
          placeholder="Octo Technology..."
          errorMsg="Organization Name Required"
        />

        <TextWithError
          name="contactName"
          label="Contact Name"
          register={register}
          errors={errors["contactName"]}
          className="my-10"
          dataTestId="contact-name"
          placeholder="Flan Fertelan..."
          errorMsg="Contact Name Required"
        />
        <EmailInput
          name="contactEmail"
          label="Contact Email"
          register={register}
          errors={errors["contactEmail"]}
          className="my-10"
          dataTestId="contact-email"
          placeholder="noreply@octo.com..."
          errorMsg="Contact Email Required"
        />
        <TextAreaSimple
          name="description"
          label="Organization Description"
          register={register}
          className="my-10"
        ></TextAreaSimple>
        <div className="flex justify-evently">
          <AddButton type="submit" buttonText="Create Organization" />
          {isLoading ? <Spinner /> : null}
        </div>
      </form>
    </>
  );
};

export default CreateOrganizationForm;
CreateOrganizationForm.propTypes = {
  onClick: PropTypes.func,

  setClose: PropTypes.func,
  SetdisplayAlert: PropTypes.func,
};
