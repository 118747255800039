import {XCircleIcon, XIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import PropTypes from "prop-types";

const DangerAlert = ({ children = "an error has occured" }) => {
  const [isDisplayed, setDisplay] = useState(true);

  const closeAlert = () => {
    setDisplay(false);
  };

  return (
    <>
      {isDisplayed ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">{children}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    data-testid="close"
                    onClick={closeAlert}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}{" "}
    </>
  );
};

export default DangerAlert;


DangerAlert.propTypes = {
  children: PropTypes.string,
};