import React, {useState} from "react";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import Avatar from "react-avatar";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import ConfirmModal from "components/Modals/ConfirmModal";

const UserList = ({ users, isLoading, role }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const queryClient = new useQueryClient();
  const { axios } = useAxios();
  const SuspendMutaion = useMutation(
    async (id) => {
      return axios.put(`/v1/organizations/suspended-members/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchmembers");
      },
    }
  );
  const confirmSuspend = () => {
    SuspendMutaion.mutate(id);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "Active":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Active
          </span>
        );
      case "Inactive":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Inactive
          </span>
        );
      case "Rejected":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            Rejected
          </span>
        );
      default:
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Unknown
          </span>
        );
    }
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Role
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((person) => (
                  <tr key={person.user.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <Avatar
                            name={`${person.user.firstName} ${person.user.lastName}`}
                            round={true}
                            size="40"
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {person.user.firstName} {person.user.lastName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {person.user.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {person?.position}{/* {person.user.lastName} */}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderSwitch(person.status)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {person.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {role === "ADMIN" ? (
                       
                          <a
                          href="#"
                          className="text-red-600 border-2 border-red-100 py-2 px-4 rounded-md hover:text-red-900 hover:bg-red-100 transition duration-150 ease-in-out"
                          onClick={() => {
                            setId(person.user?.id);
                            setOpen(true);
                          }}
                        >
                          Suspend
                        </a>
                      ) : (
                        "--"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isLoading && <Spinner />}
      <ConfirmModal
        open={open}
        action={() => confirmSuspend(id)}
        setOpen={setOpen}
        title="Suspend account"
        body="Are you sure you want to suspend this account? 
        All data will be permanently removed from our servers forever. 
        This action cannot be undone."
      />
    </div>
  );
};
UserList.propTypes = {
  users: PropTypes.array,
  role: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default UserList;
