import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./config/keycloak";
import AppRoutes from "./routes";


function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        pkceMethod: "S256",
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      }}
    >
      <AppRoutes />
    </ReactKeycloakProvider>
  );
}

export default App;
