import React, {useState} from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import RichEditor from "components/RichEditor/index.js";
import AddButton from "components/octo-ui/Button/AddButton";
import useNotesQueries from "../hooks/useNotesQueries";
import DangerAlert from "components/Alerts/DangerAlert";

const Note = ({
  note,
  currentMeeting,
  index,
  defaultCollapsed,
  onCollapse = () => {},
}) => {
  const [alert, setAlert] = useState();
  const { updateNotemutatation } = useNotesQueries(note, currentMeeting);
  const { updateNoteMoodMutation } = useNotesQueries(note);
  const [value, setValue] = useState(note.description);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  function collapse(value) {
    setCollapsed(value);
    onCollapse(value, index);
  }
  const handleSelectMood = (mood, onSuccess) => {
    updateNoteMoodMutation.mutate(
      { mood },
      {
        onSuccess,
        onError: (e) => {
          setAlert(e);
          setTimeout(() => {
            setAlert(null);
          }, 3000);
        },
      }
    );
  };

  return (
    <div className="bg-white shadow rounded-md p-4 m-4 relative">
      {alert && alert.name == "Error" ? (
        <DangerAlert>{alert.message}</DangerAlert>
      ) : (
        <></>
      )}
      <Header
        startTime={note?.startTime}
        currentMeeting={currentMeeting}
        setCollapsed={collapse}
        collapsed={collapsed}
        selectedMood={note?.mood}
        onSelectMood={handleSelectMood}
      />

      <div className="bg-white">
        {collapsed && (
          <RichEditor
            onChange={setValue}
            readOnly={false}
            content={value}
            className={` ease-linear duration-400 `}
          />
        )}
      </div>
      <div className="flex justify-end mt-4">
        <AddButton
          isLoading={updateNotemutatation.isLoading}
          buttonText="Save meeting"
          type="submit"
          onClick={() => {
            updateNotemutatation.mutate({
              description: value,
              startTime: note?.startTime,
            });
          }}
        />
      </div>
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.object,
  currentMeeting: PropTypes.object,
  title: PropTypes.string,
  setNotes: PropTypes.func,
  setNextNote: PropTypes.func,
  onCollapse: PropTypes.func,
  index: PropTypes.number,
  defaultCollapsed: PropTypes.bool,
};

export default Note;
