import {Dialog, Disclosure, Transition} from "@headlessui/react";
import {UserGroupIcon, XIcon} from "@heroicons/react/outline";
import Avatar from "react-avatar";
import {Fragment} from "react";
import PropTypes from "prop-types";
import {useAxios} from "hooks/useAxios";
import {useMutation} from "react-query";
import {classNames} from "utils/utils";

const SideBar = ({
  team,
  sidebarOpen,
  setSidebarOpen,
  setMeeting,
  currentUser,
  setMember,
}) => {
  const { axios } = useAxios();

  const navigation = [
    { name: "1-on-1s", href: "#", icon: UserGroupIcon, current: false },
  ];

  const mutatation = useMutation(
    (newMeeting) => {
      setMember(newMeeting.member);
      return axios.post(`/v1/meetings/`, newMeeting);
    },
    {
      onSuccess: (res) => {
        setMeeting(res.data);
      },
    }
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex h-100  lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    data-testid="disclosure-btn"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              <div className="items-center mt-20">
                {team?.map((subItem) => (
                  <button
                    type="button"
                    key={subItem.id.userId}
                    onClick={() => {
                      mutatation.mutate({
                        participant: subItem?.user?.email,
                        title: subItem?.user?.email,
                        owner: currentUser,
                        member:
                          subItem.user.firstName + "  " + subItem.user.lastName,
                      });
                    }}
                    className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    <Avatar
                      name={`${subItem.user?.firstName} ${subItem.user?.lastName}`}
                      size="30"
                      className="h-8 w-8 rounded-full mr-2"
                    />
                    {`${subItem.user?.firstName} ${subItem.user?.lastName}`}
                  </button>
                ))}
              </div>
            </div>
          </Transition.Child>

          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 max-h-100 border-r border-gray-200 bg-gray-100">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <nav className="mt-5 flex-1" aria-label="Sidebar">
                <div className="px-2 space-y-1">
                  {navigation.map((item) =>
                    !team ? (
                      <div key={item.name}>
                        <a
                          href="#"
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </div>
                    ) : (
                      <Disclosure
                        as="div"
                        defaultOpen={true}
                        key={item.name}
                        className="space-y-1"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-100 text-gray-900"
                                  : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500"
                              )}
                            >
                              <item.icon
                                className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  open
                                    ? "text-gray-400 rotate-90"
                                    : "text-gray-300",
                                  "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {team.map((subItem) => (
                                <Disclosure.Panel
                                  as="button"
                                  key={subItem.id.userId}
                                  onClick={() => {
                                    mutatation.mutate({
                                      participant: subItem?.user?.email,
                                      title: subItem?.user?.email,
                                      owner: currentUser,
                                      member:
                                        subItem.user.firstName +
                                        "  " +
                                        subItem.user.lastName,
                                    });
                                  }}
                                  className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                                >
                                  <Avatar
                                    name={`${subItem.user?.firstName} ${subItem.user?.lastName}`}
                                    size="30"
                                    className="h-8 w-8 rounded-full mr-2"
                                  />
                                  {`${subItem.user?.firstName} ${subItem.user?.lastName}`}
                                </Disclosure.Panel>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
SideBar.propTypes = {
  team: PropTypes.array,
  setMeeting: PropTypes.func,
  setMember: PropTypes.func,
  setSidebarOpen: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  currentUser: PropTypes.string,
};
export default SideBar;
