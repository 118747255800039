const renderFeedbackBadge = (param, feedback,user) => {
    if (param == "DRAFT") {
        if (user.user?.email == feedback.receiver.email) {
            feedback.status = "Draft:ask";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    Draft: Ask
                </span>
            );
        }
        else if (user.user?.email == feedback.writer.email) {
            feedback.status = "Draft:give";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    Draft: Give
                </span>
            );
        }
    }
    if (param == "COMPLETED") {
        if (user.user?.email == feedback.receiver.email) {
            feedback.status = "Received";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Received
                </span>
            );
        }
        else if (user.user?.email == feedback.writer.email) {
            feedback.status = "Given";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-brand-100 text-brand-800">
                    Given
                </span>
            );
        }
    }
    if (param == "PENDING") {

        if (user.user?.email == feedback.writer.email) {
            feedback.status = "Awaiting Response";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Awaiting Response
                </span>
            );
        }
        if (user.user?.email == feedback.receiver.email) {
            feedback.status = "Requested";
            return (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                    Requested
                </span>
            );
        }
    }
    else return (<span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800'> Unknown</span>);
};

export default renderFeedbackBadge;