import {React} from "react";
import PropTypes from "prop-types";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import {renderSwitchInvite} from "../utils/renderSwitch";

const InviteesList = ({ invited }) => {
  const queryClient = new useQueryClient();
  const { axios } = useAxios();



  const deleteInviteMutation = useMutation(
    async (id) => {
      return axios.delete(`/v1/organizations/invitations/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchInvites");
      },
    }
  );


  return (
    <div className="flex flex-col" data-testid="invitedlist">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th> 
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Role
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invited
                  ?.filter((invitation) => invitation.status == "PENDING")
                  .map((invitation) => (
                    <tr key={invitation.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm text-gray-500">
                              {invitation.userEmail}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {renderSwitchInvite(invitation.status)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {invitation.role}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap    text-center text-sm font-medium">
                        <a
                          href="#"
                          className="text-red-600 border-2 border-red-100 py-2 px-4 rounded-md hover:text-red-900 hover:bg-red-100 transition duration-150 ease-in-out"
                          onClick={() =>
                            deleteInviteMutation.mutate(invitation.id)
                          }
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

InviteesList.propTypes = {
  user: PropTypes.object,
  invited: PropTypes.array,
  
};
export default InviteesList;
