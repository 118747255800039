import React, {useEffect} from "react";
import "./Home.css";
import stairway from "assets/stairs.png";
import arrow from "assets/fleche.png";
import {useAuth} from "hooks/useAuth";
import {useNavigate} from "react-router-dom";

const Home = () => {
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (isAuthenticated) {
      navigate("/app");
    }
  }, [isAuthenticated]);

  return (
    <div className="landingPage bg-[url('assets/zwa9at.png')] bg-contain bg-no-repeat md:bg-center-bottom">
      <div className="fixed flex top-0 py-8 w-full place-content-around items-center">
        <div className="font-chewer text-2xl">MY PATH</div>
        <div className="middle w-1/6">
          <ul className="flex place-content-between"></ul>
        </div>
          <button
            type="button"
            onClick={() => login()}
            className="px-6 py-3 hover:-translate-y-1 font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-500 focus:outline-none transition delay-50 duration-300 ease-in-out"
          >
            Get started
          </button>
      </div>
      <div className="absolute top-10 lg:pl-40 pt-20">
        <div className="relative inline font-chewer text-5xl uppercase pl-48">
          <h1>Empower teams to</h1>
          <h1>
            reach their{" "}
            <span className="font-allura text-8xl font-black text-cyan-500 lowercase">
              highest
            </span>
            <img
              className="absolute -bottom-40 left-15 transform scale-50"
              src={arrow}
              alt="arrow"
            ></img>
          </h1>
          <h1>potential</h1>
        </div>

        <p className="w-7/12 p-4">
          <ul>
            <li className="pb-4">
              <h3 className="font-bold">One-On-Ones</h3>
              Have meaningful 1:1s, build collaborative meeting agendas, record
              decisions, and keep each other accountable
            </li>
            <li className="pb-4">
              <h3 className="font-bold">Feedback</h3>
              Give and get meaningful, actionable feedback as work happens.
              Request and track real-time feedback on recent projects, and
              performance.
            </li>
            <li className="pb-4">
              <h3 className="font-bold">Kudos</h3>
              Give a gesture of thanks, show someone you care, acknowledge that
              you appreciate someone’s efforts.
            </li>
          </ul>
        </p>
      </div>
      <img
        src={stairway}
        alt="stairway to nirvana"
        className="absolute bottom-0 right-0 h-5/6 invisible md:visible"
      />
      
    </div>
  );
};

export default Home;
