import {EyeIcon, EyeOffIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {useState} from "react";
import {useMutation} from "react-query";
import {useAxios} from "hooks/useAxios";
import Pulse from "./Pulse";

export default function PrivateNote({ privateNote }) {
  const [showPrivateText, setShowPrivateText] = useState(false);
  const { axios } = useAxios();

  const mutatation = useMutation((Reqdata) => {
    return axios.put(`/v1/private-notes/${privateNote?.id}`, Reqdata);
  });

  const handleSave = (e) => {
    mutatation.mutate({
      text: e.target.value,
    });
  };

  return (
    <div>
      <label
        htmlFor="comment"
        className="block text-sm font-medium text-gray-700"
      >
        <div className="flex space-x-2">
        <span>Private notes</span>
          {showPrivateText ? (
            <EyeIcon
              onClick={() => setShowPrivateText(!showPrivateText)}
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <EyeOffIcon
              onClick={() => setShowPrivateText(!showPrivateText)}
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          )}
        </div>
      </label>
      <div className="mt-1">
        <textarea
          style={{
            display: showPrivateText ? "block" : "none",
          }}
          onBlur={(e) => handleSave(e)}
          rows={8}
          name="comment"
          id="comment"
          defaultValue={privateNote?.text}
          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
        </textarea>
        <div
          style={{
            display: !showPrivateText ? "block" : "none",
          }}
        >
          <Pulse />
        </div>
      </div>
    </div>
  );
}
PrivateNote.propTypes = {
  privateNote: PropTypes.object,
};
