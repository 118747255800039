import React, {useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import useAddEventQuery from "../hooks/useAddEventQuery";

const CreateEventForm = ({
  closeModal,
  setAddEvent,
  currentMeeting,
  onCreateNewNote,
}) => {
  const [requestedData, setRequestedData] = useState({});

  const { createEventMutation } = useAddEventQuery(
    setAddEvent,
    requestedData,
    onCreateNewNote
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      eventTitle:
        "O3 " +
        currentMeeting?.participant.firstName +
        " / " +
        currentMeeting?.owner.firstName,
      duration: 30,
      recurrence: "ONE_TIME",
      startTime: new Date().toISOString(),
    },
  });
  const Onsubmit = (data) => {
    const startTime = new Date(data.startTime);
    const endTime = new Date(data.startTime);
    endTime.setTime(startTime.getTime() + data.duration * 60 * 1000);

    const meeting = {
      ...data,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      participant: {
        id: currentMeeting?.participant.id,
        email: currentMeeting?.participant.email,
      },
    };

    setRequestedData(meeting);
    createEventMutation.mutate();
    closeModal();
  };
  return (
    <>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-2">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-6 sm:mt-0 ">
        <div className="md:grid md:grid-cols-1 md:gap-6 m-0">
          <div className="mt-2 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit((data) => Onsubmit(data))}>
              <div className=" overflow-hidden sm:rounded-md">
                <div className="grid gap-y-4 py-1 bg-white sm:p-4 ">
                  <div>
                    <label
                      htmlFor="eventTitle"
                      className="block font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="eventTitle"
                      data-testId="eventTitle"
                      {...register("eventTitle", {
                        required: "Event title is required",
                      })}
                      autoComplete="eventTitle"
                      className="mt-1 py-3 bg-gray-50 block w-full border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <p className="text-sm font-light leading-relaxed mt-0 text-red-500">
                      {errors.eventTitle?.message}
                    </p>
                  </div>
                  <div>
                    <div className="grid grid-cols-4 gap-6 mt-5">
                      <div className="col-span-3">
                        <label
                          htmlFor="startTime"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date
                        </label>
                        <input
                          type="datetime-local"
                          name="startTime"
                          {...register("startTime", {
                            required: "Start Time is required",
                          })}
                          id="startTime"
                          autoComplete="startTime"
                          className="mt-1 py-3 bg-gray-50 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                          {errors.startTime?.message}
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="startTime"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Duration
                        </label>
                        <input
                          type="number"
                          name="duration"
                          defaultValue={30}
                          {...register("duration", {
                            required: "duration Time is required",
                          })}
                          className="mt-1 py-3 bg-gray-50 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                          {errors.duration?.message}
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-3  mt-0">
                      <div>
                        <input
                          type="radio"
                          value="ONE_TIME"
                          name="recurrence"
                          {...register("recurrence")}
                        />{" "}
                        One Time
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="EVERY_WEEK"
                          name="recurrence"
                          {...register("recurrence")}
                        />{" "}
                        Every Week
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="EVERY_TWO_WEEKS"
                          name="recurrence"
                          {...register("recurrence")}
                        />{" "}
                        Every Two Week
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <textarea
                      id="description"
                      name="description"
                      {...register("description")}
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Add description..."
                    ></textarea>
                  </div>
                </div>
                <div className="px-3 py-2 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-4">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  );
};

export default CreateEventForm;
CreateEventForm.propTypes = {
  closeModal: PropTypes.func,
  setStartTime: PropTypes.func,
  setAddEvent: PropTypes.func,
  currentMeeting: PropTypes.object,
  onCreateNewNote: PropTypes.func,
};
