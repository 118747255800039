import {useParams} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import AlertMessage from "./AlertMessage";
import {useCreateRedeemRequestMutation} from "./useRedeemRequests";
import {useGetRewardById} from "./useRewards";

export default function RedeemPage() {
  const { user } = useAuth();
  const { id: rewardId } = useParams();

  const redeemMutatation = useCreateRedeemRequestMutation({ rewardId });

  const {
    data: reward,
    isLoading,
    isError,
  } = useGetRewardById({ rewardId });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading reward detail.</div>;
  }

  const pointsAfterRedeem =
    user?.organizationMembers?.kudoPoints - reward?.points;

  return (
    <div className="mx-auto max-w-2xl px-4 pt-20 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 my-8">
        Redeem your Kudo points
      </h1>
      <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
        <section aria-labelledby="cart-heading" className="lg:col-span-7">
          <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
            <img
              src={reward.image}
              alt={reward.name}
              className="h-full w-full object-fit object-center"
            />
          </div>

          <div className="lg:col-span-2 lg:pr-8 pt-3">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {reward.name}
            </h1>
          </div>

          <div className="pt-4 flex flex-1 justify-between">
            <p className="text-2xl tracking-tight text-gray-900">
              {reward.points} points
            </p>
            <p className="text-xl text-green-500">
              {reward.quantity} Available
            </p>
          </div>

          <div>
            <h3 className="sr-only">Description</h3>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
              <div className="space-y-6">
                <p className="text-base text-gray-900">{reward.description}</p>
              </div>
            </div>
          </div>
        </section>

        <section
          aria-labelledby="summary-heading"
          className="mt-16 rounded-lg bg-gray-100 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
        >
          {redeemMutatation.isSuccess ? (
            <AlertMessage
              title="Request successfuly submitted"
              message="Your redeem request has been successfully submitted and will be processed as soon as possible."
              link="/app/rewards"
            />
          ) : redeemMutatation.isError ? (
            <AlertMessage
              type="error"
              title="Request failed"
              message={redeemMutatation.error}
              link="/app/rewards"
            />
          ) : (
            <>
              <h2
                id="summary-heading"
                className="text-lg font-medium text-gray-900"
              >
                Summary
              </h2>

              <dl className="mt-6 space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-sm text-gray-600">Your points</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {user?.organizationMembers?.kudoPoints}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex items-center text-sm text-gray-600">
                    <span>Roward required points</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {reward.points}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="text-base font-medium text-gray-900">
                    Your points after redeem
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    {pointsAfterRedeem}
                  </dd>
                </div>
              </dl>

              <div className="mt-6">
                <button
                  type="button"
                  onClick={() => redeemMutatation.mutate()}
                  disabled={pointsAfterRedeem < 0 || redeemMutatation.isLoading}
                  className={`${
                    (pointsAfterRedeem < 0 || redeemMutatation.isLoading) &&
                    "cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none"
                  }  w-full rounded-md border border-transparent bg-brand-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 focus:ring-offset-gray-50`}
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
}
