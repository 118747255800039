import {useAuth} from "hooks/useAuth";
import {useGetRedeemRequests} from "./useRedeemRequests";
import RequestsList from "./RequestsList";

const RedeemRequestsPage = () => {
  const { user } = useAuth();
  const { data: redeemRequests, isLoading, isError } = useGetRedeemRequests();

  if (isError) {
    return <div>Error loading rewards.</div>;
  }

  return (
    <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 my-8">
        Kudo Points Redeem Requests
      </h1>

      {!redeemRequests || redeemRequests.length === 0 ? (
        <div className="rounded-md bg-blue-100 p-4 mb-10">
          <div className="flex">
            <div className="ml-3 flex-1 md:flex md:justify-center">
              <p className="text-sm text-blue-700">
                There is no redeem requests right now
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <RequestsList
            requests={redeemRequests}
            isLoading={isLoading}
            role={user?.organizationMembers?.role}
          />
        </div>
      )}
    </div>
  );
};

export default RedeemRequestsPage;
