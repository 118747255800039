import React from 'react'
import PropTypes from 'prop-types';
import {MailOpenIcon} from '@heroicons/react/solid';

const Invitation = ({ company, onClick }) => {
    return (
        <div className='flex flex-col items-center justify-around w-1/2 h-64 border-1 drop-shadow-xl bg-white py-8 rounded-md'>
            <MailOpenIcon className=" h-12 fill-gray-900"/>
            <h2>You have been invited to join <span className='font-bold'>{company}</span></h2>
            <button
                className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500'
                onClick={onClick}>
                Accept invitation
            </button>
        </div>
    )
}

export default Invitation
Invitation.propTypes = {
    company: PropTypes.string,
    onClick: PropTypes.func
};