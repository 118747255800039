import React, {useState} from "react";
import SuccessAlert from "components/Alerts/SuccessAlert";
// import UserDetails from "./UserDetails";
import UserDetails2 from "./UserDetails2";
import {useParams} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import CurrentUserDetails from "./CurrentUserDetails";

const UserProfile = () => {
  const { user } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [alert, setAlert] = useState(false);

  const displayAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  const { id: userId } = useParams();

  return (
    <>
      <div className="sm:mt-0 pt-16 bg-slate-100 ">
        {alert && <SuccessAlert message="User Profile upadted successfully" />}

        {/* <UserDetails
            user={user}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setDispledAlert={displayAlert}
          /> */}
        {userId == "current" ? (
          <CurrentUserDetails
            user={user}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setDispledAlert={displayAlert}
          />
        ) : (
          <UserDetails2 user={user} userId={userId} />
        )}
      </div>
    </>
  );
};

export default UserProfile;
