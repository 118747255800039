import React from "react";
import {PlusSmIcon, TrashIcon, XIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {ArchiveIcon, CheckIcon} from "@heroicons/react/outline";
import {useFieldArray, useForm} from "react-hook-form";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import RichEditor from "components/RichEditor";
import SelectMultipleUsers from "components/Forms/Select/SelectMultipleUsers";

const FeedbackAskForm = ({ onClose, members, user, setDispledAlert }) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();

  const createFeedbackApi = async (data) => {
    return axios.post(
      `/v1/feedbacks?feedbackStatus=${data.feedbackStatus}`,
      data
    );
  };
  const mutation = useMutation(
    (newFeedbackRequest) => {
      return createFeedbackApi(newFeedbackRequest);
    },
    {
      onSuccess: () => {
        setDispledAlert();
        queryClient.invalidateQueries(["feedbacks"]);
        onClose();
      },
    }
  );

  const feedbackTemplate = {
    subject: "Feedback 360° sur ma contribution",
    questions: [
      {
        question:
          "<strong>Appréciation</strong> : <em>Qu’appréciez-vous dans l’état actuel de mes contributions</em>",
        type: "text",
      },
      {
        question:
          "<strong>Amplification</strong> : <em>Dans ce qui marche déjà bien, qu’est-ce qui pourrait être amplifié pour aller encore plus loin ?</em>",
        type: "text",
      },
      {
        question:
          "<strong>Ajustement</strong> : <em>Qu’est-ce qui pourrait être ajusté/ajouté pour améliorer encore plus le tout ?</em>",
        type: "text",
      },
      {
        question: "Comment qualifieriez-vous mon impact actuel sur la mission?",
        type: "rating",
      },
      {
        question:
          "Comment qualifieriez-vous mon impact actuel sur la communauté des Octos?",
        type: "rating",
      },
    ],
  };

  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: feedbackTemplate,
  });

  const {
    fields: questionField,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "questions",
  });

  const setQuestion = (value, index) => {
    setValue(`questions.${index}.question`, value);
  };

  const getEmails = (writers) => {
    return writers.map((writer) => writer.email);
  };

  const onSubmit = (data) => {
    data.receivers = [user.user.email];
    data.feedbackStatus = "Pending";
    data.writers = getEmails(data.writers[0]);
    mutation.mutate(data);
  };
  const onDraft = (data) => {
    data.receivers = [user.user.email];
    data.feedbackStatus = "Draft";
    data.writers = getEmails(data.writers[0]);
    mutation.mutate(data);
  };

  return (
    <div className={`flex flex-col py-6  h-100 px-6`}>
      {/*--- HEADER START ---*/}

      <div className="lg:flex lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            Ask for feedback
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4 self-start">
          <span className="hidden sm:block">
            <button
              onClick={() => onClose()}
              data-testid="discard"
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <XIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              Discard
            </button>
          </span>
          <span className="hidden sm:block sm:ml-2">
            <button
              onClick={handleSubmit(onDraft)}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <ArchiveIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
                data-testid="draft"
              />
              Draft
            </button>
          </span>
          <span className="sm:ml-3">
            <button
              onClick={handleSubmit(onSubmit)}
              data-testid="send"
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Send
            </button>
          </span>
        </div>
      </div>
      {/*--- HEADER END ---
    
    
    --- FORM START ---*/}
      <div className="py-5">
        <ul className="space-y-3 ">
          <li className="bg-white shadow rounded-md px-6 py-4 z-[120]">
            <div>
              <div className=" font-semibold mt-4">Request feedback from</div>
              <SelectMultipleUsers
                people={members}
                defaultValue={members[0]?.user}
                control={control}
                name={`writers.${0}`}
              />
              <div className="font-semibold mt-4">Subject</div>
              <input
                data-testid="subject"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                type="text"
                placeholder="Title example"
                {...register("subject", { required: true })}
              />
            </div>
          </li>

          {questionField.map((item, index) => {
            return (
              <li
                key={index}
                data-testid="question"
                className="bg-white shadow rounded-md px-6 py-4"
              >
                <div className="flex items-center justify-between">
                  <div className="text-sm mb-2 flex items-center">
                    <div>
                      <input
                        type="radio"
                        value="text"
                        name={`questions.${index}.type`}
                        {...register(`questions.${index}.type`)}
                      />{" "}
                      Text
                      <input
                        className="ml-4"
                        type="radio"
                        value="rating"
                        name={`questions.${index}.type`}
                        {...register(`questions.${index}.type`)}
                      />{" "}
                      Rating
                    </div>
                  </div>
                  <TrashIcon
                    onClick={() => removeQuestion(index)}
                    className="text-gray-500 h-5 cursor-pointer ml-4"
                    data-testid={`delete-question.${index}`}
                  />
                </div>
                <div className="grow flex  items-center justify-between">
                  <RichEditor
                    content={watch(`questions.${index}.question`)}
                    onChange={(e) => setQuestion(e, index)}
                    className="w-full min-h-[7rem] "
                    compact
                    placeholder= 'Compose an your question...'
                  />
                </div>
              </li>
            );
          })}
        </ul>

        <div className="relative mt-3">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              onClick={() =>
                appendQuestion([{  type: "text" }])
              }
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <PlusSmIcon
                className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Add Question</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackAskForm;

FeedbackAskForm.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  members: PropTypes.array,
  compact: PropTypes.bool,
  toggleCompact: PropTypes.func,
  setDispledAlert: PropTypes.func,
};
