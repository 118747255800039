import {useMutation} from "react-query";
import {useAxios} from "hooks/useAxios";

const useAddEventQuery = (setAddEvent, requestedData, onCreateNewNote) => {
  const { axios } = useAxios();
  const callApi = async () => {
    return axios.post("/v1/meetings/event", requestedData);
  };
  const createEventMutation = useMutation(callApi, {
    onSuccess: (result) => {
      onCreateNewNote({
        startTime: requestedData?.startTime,
        recurrence: requestedData?.recurrence,
        isPlanned: true,
        eventId: result?.data?.id,
      });
      setAddEvent(false);
    },
  });
  return { createEventMutation };
};

export default useAddEventQuery;
