import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {feedabcksKeys} from "./keys";

const ENDPOINT = "/v1/feedbacks";

const useFeedbacksQuery = () => {
  const { axios } = useAxios();

  const fetchFeedbacks = async () => {
    return axios.get(`${ENDPOINT}`);
  };
  return useQuery(feedabcksKeys.default, fetchFeedbacks);
};

export default useFeedbacksQuery;
