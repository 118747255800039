import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Nav from "../components/Nav";
import routes from "./routes.js";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, component: Component, isPrivate }) =>
          isPrivate ? (
            <Route
              key={path}
              path={path}
              element={
                <div>
                  <Nav />
                  <PrivateRoute component={Component} />
                </div>
              }
            />
          ) : (
            <Route  key={path} path={path} element={<Component />} />
          )
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
