import React, {Fragment, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import SearchUsers from "../SearchUsers";
import Avatar from "react-avatar";
import logo from "assets/mypath-whitetext.svg";
import logomin from "assets/mypath.svg";
import Notifications from "../Notifications";
import {classNames} from "utils/utils";

const Nav = () => {
  const { user, logout } = useAuth();
  const navigation = [
    { name: "Dashboard", href: "/app" },
    { name: "1-on-1s", href: "/app/meeting" },
    { name: "Feedbacks", href: "/app/feedback" },
  ];
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(
    navigation.find((nav) => nav.href === pathname)?.name
  );

  const switchCurrent = (item) => {
    setCurrent(item);
  };

  return (
    <div>
      <Disclosure
        as="nav"
        className=" fixed z-50 w-screen bg-brand-600 shadow-sm"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="bg-brand-600 inline-flex items-center justify-center p-2 rounded-md text-brand-200 hover:text-white hover:bg-brand-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-600 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-7 w-auto"
                      src={logomin}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-7 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => switchCurrent(item.name)}
                          className={classNames(
                            current == item.name
                              ? "bg-brand-700 text-white"
                              : "text-white hover:bg-brand-500 hover:bg-opacity-75",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <SearchUsers organizationId={user?.organization?.id} />
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Notifications />

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative z-[120]">
                    <div>
                      <Menu.Button className="">
                        <span className="sr-only">Open user menu</span>
                        <Avatar
                          color="#007F93"
                          name={`${user?.user?.firstName} ${user?.user?.lastName}`}
                          size="40"
                          className="h-8 w-8 rounded-full"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {user?.organizationMembers?.role === "ADMIN" && (
                          <>
                           <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={"/app/reports"}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Reporting
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={"/app/team"}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Account
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={"/app/redeem-requests"}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Admin
                                </Link>
                              )}
                            </Menu.Item>
                          </>
                        )}

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/app/profile/current"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => logout()}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-brand-900 text-white"
                        : "text-white hover:bg-brand-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Nav;
