import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import {notificationsKeys} from "./keys";

const ENDPOINT = "/v1/notifications";

const useNotificationsDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();

  return useMutation(
    (notificationId) => {
      return axios.delete(`${ENDPOINT}/${notificationId}`);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(notificationsKeys.default);
      },
    }
  );
};

export default useNotificationsDeleteMutation;
