import {React} from "react";
import PropTypes from "prop-types";
import ReactStars from "react-rating-stars-component";

const Rating = ({ isEdit, value, onChange }) => {
  const props = {
    size: 20,
    count: 5,
    color: "gold",
    activeColor: "gold",
    value: value,
    isHalf: true,
    edit: isEdit,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: onChange,
  };

  return <ReactStars {...props}></ReactStars>;
};

export default Rating;

Rating.propTypes = {
  isEdit: PropTypes.bool,
  field: PropTypes.object,
  value: PropTypes.number,
  onChange: PropTypes.func,
};
