import PropTypes from "prop-types";
import Avatar from "react-avatar";
import {XIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

import Spinner from "../../Spinner";
import useNotificationsDeleteMutation
  from "./../hooks/useNotificationsDeleteMutation";
import useNotificationsSeenUpdateMutation
  from "./../hooks/useNotificationsSeenUpdateMutation";
import NotificationEmptyState from "../EmptyState";

export default function NotificationResult({ isLoading, notifications }) {
  const notificationsSeenUpdateMutation = useNotificationsSeenUpdateMutation();
  const notificationsDeleteMutation = useNotificationsDeleteMutation();

  return (
    <div className="absolute top-14  left-200 right-10 bg-white shadow overflow-hidden rounded-lg max-w-md px-2 py-2 h-80 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded scrollbar-track-rounded z-[120]">
      <h4 className="text-lg font-bold leading-7 text-gray-900 my-2 mx-2">
        Notifications
      </h4>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {notifications?.length == 0 ? (
            <NotificationEmptyState />
          ) : (
            <ul
              role="list"
              className="divide-y divide-gray-100 last-divide-y-0 "
            >
              {notifications.map((notification) => (
                <li key={notification?.id} data-testid="item-notif">
                  <Link
                    to={`/app/${notification?.event.type}/`}
                    onClick={() =>
                      notificationsSeenUpdateMutation.mutate(notification?.id)
                    }
                  >
                    <div
                      data-testid="notif"
                      className="flex items-center py-4 px-4 space-x-4 hover:bg-gray-100 rounded-lg ease-in-out duration-300 group relative"
                    >
                      <div className="flex-shrink-0">
                        <Avatar
                          name={`${notification?.userWhoFiredEvent.firstName} ${notification?.userWhoFiredEvent.lastName}`}
                          size="40"
                          round={true}
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900">
                          {notification?.event.description}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(notification?.createdAt).toUTCString()}
                        </p>
                      </div>
                      {!notification?.seen && (
                        <div
                          data-testid="seen"
                          className="w-2 h-2 bg-red-400 rounded-full "
                        />
                      )}
                      <button
                        className="mb-10 hidden group-hover:block absolute top-2 right-2" 
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          notificationsDeleteMutation.mutate(notification?.id);
                        }}
                      >
                        <span className="sr-only" data-testid="deletenotif">
                          Close
                        </span>
                        <XIcon
                          className="h-3 w-3 hover:bg-red-100 rounded-lg ease-in-out"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

NotificationResult.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
};
