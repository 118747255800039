export default function NotificationEmptyState() {
  return (
    <div className="text-center p-2 pt-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>

      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No notifications yet
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Stay tuned ! Notifications will show up here.
      </p>
    </div>
  );
}
