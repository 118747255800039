import React, {useEffect, useState} from "react";
import EmptyState from "components/Empty-state";
import CreateOrganizationForm from "./CreateOrganizationForm";
import Modal from "components/Modals";
import SuccessAlert from "components/Alerts/SuccessAlert";
import {useAxios} from "hooks/useAxios";
import {useAuth} from "hooks/useAuth";
import Invitation from "./Invitation";
import {UserGroupIcon} from "@heroicons/react/solid";
import Dashboard from "../Dashboard";
import Kudo from "../Kudo";
import {useMutation, useQuery, useQueryClient} from "react-query";

const DashBoardPage = () => {
  const { user } = useAuth();
  const [userProfile, setUserProfile] = useState({ user });

  const queryClient = useQueryClient();
  //modal form functions
  let [isFormOpen, setIsFormOpen] = useState(false);

  function closeModal() {
    setIsFormOpen(false);
  }
  function openModal() {
    setIsFormOpen(true);
  }

  const [alert, setAlert] = useState(false);
  const displayAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
    location.reload();
  };
  // end modal form functions

  //API CALL CONFIG

  const { axios } = useAxios();

  const callAcceptInvitation = async () => {
    return await axios
      .put(`/v1/organizations/invitations/${userProfile?.invitation?.id}/`, {
        response: "ACCEPTED",
      })
      .then(location.reload());
  };
  const acceptInviteMutation = useMutation(callAcceptInvitation, {
    onSuccess: () => {
      queryClient.refetchQueries(["fetchInvites"]);
    },
  });

  useEffect(() => {
    setUserProfile(user);
  }, [user, alert]);

  const currentUserId = user?.user?.id;
  const fetchManagedTeam = async () => {
    return axios.get(`/v1/organizations/${currentUserId}/managed-team`);
  };
  const fetchedManagedTeam = useQuery(
    ["team", currentUserId],
    () => fetchManagedTeam(),
    {
      enabled: !!currentUserId && !!userProfile?.organization,
    }
  );
  return (
    <main className="flex-1 pt-16">
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
          {alert && (
            <SuccessAlert
              message="Organization created successfully"
              position="absolute top-20 "
            />
          )}
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="flex flex-col items-center justify-center">
              {userProfile?.organization === null &&
                userProfile?.invitation === null && (
                  <>
                    <EmptyState
                      onCreateNew={openModal}
                      btnText={"new Organization"}
                      paragraph={"You do not belong to any organization"}
                      paragraph2={"Get started by creating a new one."}
                      hint={"Or Ask your organization Admin for an invitation"}
                      Icon={UserGroupIcon}
                    />
                    {isFormOpen && (
                      <Modal
                        isOpen={isFormOpen}
                        onClose={closeModal}
                        title="Create Organization"
                      >
                        <CreateOrganizationForm
                          setClose={setIsFormOpen}
                          SetdisplayAlert={displayAlert}
                        />
                      </Modal>
                    )}
                  </>
                )}

              {/* If user has an invitation*/}
              {userProfile?.organization === null &&
                userProfile?.invitation !== null && (
                  <Invitation
                    company={userProfile?.invitation?.organization?.name}
                    onClick={() => acceptInviteMutation.mutate()}
                  />
                )}

              {/* If user belongs to an organization*/}
              {userProfile?.organization !== null &&
                userProfile?.organization !== undefined &&
                (fetchedManagedTeam?.data?.data.length > 0 ? (
                  <>
                    <Kudo user={user} />
                    <Dashboard currentUser={user} />
                  </>
                ) : (
                  <Kudo user={user} />
                ))}
              {/* /End API Success */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashBoardPage;
