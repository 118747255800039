import {BellIcon} from "@heroicons/react/outline";
import {useState} from "react";
import {useClickOutside} from "../../hooks/useClickOutside";
import PropTypes from "prop-types";
import NotificationResult from "./NotificationResult";
import useNotificationsUserQuery from "./hooks/useNotificationsUserQuery";

export default function Notifications({ user, token }) {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNotificationsSign, setShowNotificationsSign] = useState(true);

  const notificationsUser = useNotificationsUserQuery(user, token);

  const handleNotificationsIconClick = () => {
    setShowNotifications(!showNotifications);
  };

  let domNode = useClickOutside(() => {
    setShowNotifications(false);
  });

  return (
    <div ref={domNode}>
      <button
        onClick={handleNotificationsIconClick}
        type="button"
        data-testid="shownotif"
        className="bg-brand-600 p-1 rounded-full text-brand-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-600 focus:ring-white relative"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon
          onClick={() => setShowNotificationsSign(false)}
          className="h-6 w-6"
          aria-hidden="true"
        />
        {notificationsUser.data?.data.length > 0 && showNotificationsSign && (
          <div className="w-2 h-2 bg-red-400 rounded-full animate-ping absolute top-0 right-0" />
        )}
      </button>
      <div
        style={{
          display: showNotifications ? "block" : "none",
        }}
      >
        <NotificationResult
          isLoading={notificationsUser.isLoading}
          notifications={notificationsUser.data?.data || []}
        />
      </div>
    </div>
  );
}
Notifications.propTypes = {
  currentUserId: PropTypes.string,
  user: PropTypes.object,
  token: PropTypes.string,
};
