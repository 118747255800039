import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {useAuth} from "hooks/useAuth";

const ENDPOINT = "/v1/notifications";

const useNotificationsUserQuery = () => {
  const { axios } = useAxios();
  const {user} = useAuth()

  const notificationRequest = async () => {
      return axios.get(`${ENDPOINT}/`);
  };

  const userId = user?.user?.id;

  return useQuery(["notifications", userId], () => notificationRequest(),
    { enabled: !!userId ,
      retry : true});
};

export default useNotificationsUserQuery;
