import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {managedTeamKeys} from "./keys";

const ENDPOINT = "/v1/organizations";

const useManagedTeamQuery = (userId) => {
  const { axios } = useAxios();

  const fetchManagedTeam = async () => {
    return axios.get(`${ENDPOINT}/${userId}/team`);
  };
  return useQuery(managedTeamKeys.user(userId), () => fetchManagedTeam(), {
    enabled: !!userId,
  });
};

export default useManagedTeamQuery;
