import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import AddButton from "./AddButton";
import useNotesQueries from "../hooks/useNotesQueries";

const NextNote = ({ note, currentMeeting, nonePlannedNotes }) => {
  const { updateNotemutatation, createNotemutatation, deleteNoteMutatation } =
    useNotesQueries(note, currentMeeting);

  return (
    <>
      <div className="bg-white shadow rounded-md p-4 m-4">
        <Header
          note={note}
          currentMeeting={currentMeeting}
          deleteNote={deleteNoteMutatation.mutate}
        />
        <div className="flex justify-end mt-4">
          <AddButton
            isLoading={createNotemutatation.isLoading}
            buttonText="Start meeting"
            type="submit"
            onClick={() => {
              if (note?.recurrence === "ONE_TIME") {
                updateNotemutatation.mutate({
                  startTime: new Date(),
                  description: nonePlannedNotes[0]?.description,
                  isPlanned: false,
                });
              } else {
                createNotemutatation.mutate({
                  startTime: new Date(),
                  description: nonePlannedNotes[0]?.description,
                  isPlanned: false,
                  recurrence: "ONE_TIME",
                });
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

NextNote.propTypes = {
  note: PropTypes.object,
  currentMeeting: PropTypes.object,
  nonePlannedNotes: PropTypes.array,
};

export default NextNote;
