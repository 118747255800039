import React, {useState} from "react";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import Avatar from "react-avatar";
import ConfirmModal from "components/Modals/ConfirmModal";
import {useUpdateRequestMutation} from "./useRedeemRequests";

const RequestsList = ({ requests, isLoading, role }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("PENDING");

  const updateRequestMutation = useUpdateRequestMutation({ id, status});

  const confirmAction = () => {
    updateRequestMutation.mutate();
  };

  const renderStatus = (param) => {
    switch (param) {
      case "COMPLETED":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Completed
          </span>
        );
      case "CANCELLED":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Cancelled
          </span>
        );
      case "PENDING":
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            Pending
          </span>
        );
      default:
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Unknown
          </span>
        );
    }
  };

  if (isLoading) {
    return <div className="flex flex-col"><Spinner /></div>;
  }
  if (!requests || requests.length === 0) { //return empty state
    return (
      <div className="flex flex-col">
        No Requests Submitted Yet
      </div>
    );
  }

  
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reward
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {requests.map((req) => (
                  <tr key={req.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <Avatar name={`${req.requestedBy.firstName} ${req.requestedBy.lastName}`}  round={true} size="40" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {req.requestedBy.firstName}{" "}
                            {req.requestedBy.lastName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {req.requestedBy.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {new Date(req?.createdAt).toUTCString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {req.reward.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {req.points} points
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderStatus(req.status)}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {(role === "ADMIN" && req.status==="PENDING")? (
                        <button
                          href="#"
                          className="text-blue-600 border-2 border-blue-100 py-2 px-4 rounded-md hover:text-blue-900 hover:bg-blue-100 transition duration-150 ease-in-out"
                          onClick={() => {
                            setId(req.id);
                            setStatus("COMPLETED");
                            setOpen(true);
                          }}
                        >
                          Complete
                        </button>
                      ) : (
                        ""
                      )}
                       {req.status==="PENDING"? (
                        <button
                          href="#"
                          className="ml-2 text-gray-600 border-2 border-gray-100 py-2 px-4 rounded-md hover:text-red-900 hover:bg-gray-100 transition duration-150 ease-in-out"
                          onClick={() => {
                            setId(req.id);
                            setStatus("CANCELLED");
                            setOpen(true);
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmModal
        open={open}
        action={() => confirmAction(id)}
        setOpen={setOpen}
        title={status=== "COMPLETED"? "Mark this request as completed": "Cancel this request"}
        body="Are you sure you want to proceed with this action?"
      />
    </div>
  );
};
RequestsList.propTypes = {
  requests: PropTypes.array,
  role: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RequestsList;
