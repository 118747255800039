import React from "react";
import styles from "./pageLoader.module.css";

const index = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className={styles.pageLoaderCircle}>
        <div className={styles.pageLoaderInnerDot} />
        <div className={styles.pageLoaderOuterDot} />
      </div>
    </div>
  );
};

export default index;
