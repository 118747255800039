import axios from "axios";
import {useMemo, useState} from "react";
import {useAuth} from "./useAuth";

import {axiosConfig} from "config/axios";

/**
 * Returns an authorizated axios instance
 *
 * @param {Object} config is the default config to be sent to the axios creator
 *
 * @return {Object} an object containing the axios instance and the initialized prop
 *
 */
export const useAxios = (config = {}) => {
  const { token, initialized: authInitialized } = useAuth();
  const [initialized, setInitialized] = useState(false);

  const initAxios = () => {
    const instance = axios.create({
      ...axiosConfig,
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: authInitialized ? `Bearer ${token}` : undefined,
      },
    });

    setInitialized(true);
    return { instance };
  };
  const axiosInstance = useMemo(
    () => initAxios(),
    [token, authInitialized]
  );

  return { axios: axiosInstance.instance, initialized };
};

export default { useAxios };
