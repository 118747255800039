export default function Checklist() {
  const managerChecklist = [
    "Improvement feedbacks",
    "Info to share",
    "Up-to-date CRA",
    "Check objectives",
    "Topics to delegate",
    "What can I do to help?",
  ];
  const reportChecklist = [
    "Chit-Chat",
    "What would you like to discuss today?",
    "What should I know?",
  ];

  return (
    <div className="pt-4">
      <h3 className="block text-sm font-medium text-gray-700">Checklist</h3>

      <div className="border rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="">
          <div>
            <h4 className="text-sm font-semibold pb-1 m1">Consultant (10 min)</h4>
            <ul className="list-outside m-0">
              {reportChecklist?.map((item, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  />
                  <label className="text-xs">{item}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="pt-3">
            <h4 className="text-sm font-semibold pb-1 m1">Manager(10 min)</h4>
            <ul className="list-outside m-0">
              {managerChecklist?.map((item, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  />
                  <label className="text-xs">{item}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="pt-3">
            <h4 className="text-sm font-semibold pb-1 m1">
              Update goals and actions(10 min)
            </h4>
            <ul className="list-outside m-0">
              <li>
                <input
                  type="checkbox"
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                />
                <label className="text-xs">Actions will you take before next time</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
