import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAxios} from "hooks/useAxios";

const useNotesQueries = (note = {}, currentMeeting = {}) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();

  const Success = () => {
    queryClient.invalidateQueries(["plannedNotes"]);
    queryClient.invalidateQueries(["noneplannedNotes"]);
  };

  const createNotemutatation = useMutation(
    (Reqdata) => {
      return axios.post(`/v1/notes/${currentMeeting.id}`, Reqdata);
    },
    {
      onSuccess: Success,
    }
  );

  const updateNotemutatation = useMutation(
    (Reqdata) => {
      return axios.put(`/v1/notes/${note?.id}`, Reqdata);
    },
    {
      onSuccess: Success,
    }
  );

  const deleteNoteMutatation = useMutation(
    () => {
      return axios.delete(`/v1/notes/${note.id}`);
    },
    {
      onSuccess: Success,
    }
  );

  const updateNoteMoodMutation = useMutation(
    (Reqdata) => {
      return axios.patch(`/v1/notes/${note?.id}/moods/${Reqdata?.mood}`);
    },
    {
      onSuccess: Success,
    }
  );

  const NONE_PLANNED_NOTES_ENDPOINT = "/v1/notes/nonePlanned";

  const fetchNonePlannedNotes = async () => {
    return await axios.get(
      `${NONE_PLANNED_NOTES_ENDPOINT}/${currentMeeting?.id}`
    );
  };

  const nonePlannedNotesFetched = useQuery(
    ["noneplannedNotes", currentMeeting?.id],
    fetchNonePlannedNotes,
    {
      enabled: !!currentMeeting?.id,
    }
  );

  const PLANNED_NOTES_ENDPOINT = "/v1/notes/planned";

  const fetchPlannedNotes = async () => {
    return await axios.get(`${PLANNED_NOTES_ENDPOINT}/${currentMeeting?.id}`);
  };

  const plannedNotesFetched = useQuery(
    ["plannedNotes", currentMeeting?.id],
    fetchPlannedNotes,
    {
      enabled: !!currentMeeting?.id,
    }
  );

  return {
    createNotemutatation,
    updateNotemutatation,
    deleteNoteMutatation,
    nonePlannedNotesFetched,
    plannedNotesFetched,
    updateNoteMoodMutation,
  };
};

export default useNotesQueries;
