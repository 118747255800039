import React from "react";
import SelectUsers from "components/Forms/Select/SelectUsers";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";

const KudoForm = ({ members, user, onClose }) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();

  const createKudoTransactionApi = async (data) => {
    return await axios.post(`/v1/kudos/`, data);
  };

  const mutation = useMutation((newKudo) => createKudoTransactionApi(newKudo), {
    onSuccess: () => {
      queryClient.invalidateQueries("kudoTransactions");
      queryClient.invalidateQueries("members");
      queryClient.invalidateQueries("myKudoTransactions");
      queryClient.invalidateQueries("user-profile");

      onClose();
    },
  });

  const { handleSubmit, control, register } = useForm();
  const onSubmit = (data) => {
    data.receiver = data.receiver.email;
    mutation.mutate(data);
  };

  return (
    <li className="mb-10 ml-4 pb-5 bg-slate-50 px-6 py-6 mr-6 rounded-lg shadow-md">
      <div className="absolute w-3 h-3 bg-gray-300 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
      <div>
        <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:text-xl sm:truncate mb-5">
          Give Away Kudo Points
        </h2>

        <div className=" font-semibold mb-4 flex items-center">
          <label className="mr-5 inline min-w-[5rem]">To</label>
          <SelectUsers
            className="w-full"
            people={members}
            defaultValue={members[0]?.user}
            control={control}
            name="receiver"
          />
        </div>
        <div className="font-semibold mb-4 flex items-center">
          <label className="mr-5 inline min-w-[5rem]">Amount</label>
          <input
            {...register("amount", {
              required: true,
              max: user?.organizationMembers?.kudoBalance,
              min: 1,
            })}
            step="10"
            data-testid="amount"
            className="appearance-none mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
            type="number"
            placeholder="Amount"
          />
        </div>
        <div className="font-semibold mb-4 flex items-center">
          <label className="mr-5 self-start pt-4 inline min-w-[5rem]">
            Message
          </label>
          <textarea
            {...register("message", { required: true })}
            step="10"
            data-testid="amount"
            className="h-24 appearance-none mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
            type="number"
            placeholder="Message...."
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          onClick={handleSubmit(onSubmit)}
          data-testid="send"
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Send
        </button>
      </div>
    </li>
  );
};

export default KudoForm;

KudoForm.propTypes = {
  members: PropTypes.array,
  user: PropTypes.object,
  onClose: PropTypes.func,
};
