import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAxios} from "hooks/useAxios";

import {REWARDS_KEY} from "./useRewards";

const ENDPOINT = "/v1/redeem-requests";
export const REDEEM_REQUESTS_KEY = "redeem-requests";
export const MY_REDEEM_REQUESTS_KEY = "my-redeem-requests";

export const useGetRedeemRequests = () => {
  const { axios } = useAxios();
  return useQuery([REDEEM_REQUESTS_KEY], () => {
    return axios.get(ENDPOINT).then((res) => res.data);
  });
};

export const useGetMyRedeemRequests = ({ userId }) => {
  const { axios } = useAxios();
  return useQuery({
    queryKey: [MY_REDEEM_REQUESTS_KEY],
    queryFn: () =>
      axios
        .get(ENDPOINT, {
          params: {
            userId,
          },
        })
        .then((res) => res.data),
    enabled: !!userId,
  });
};

export const useCreateRedeemRequestMutation = ({ rewardId }) => {
  const queryClient = new useQueryClient();
  const { axios } = useAxios();

  const mutation = useMutation(
    async () => {
      const response = await axios.post(ENDPOINT, null, {
        params: {
          rewardId,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [MY_REDEEM_REQUESTS_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [REDEEM_REQUESTS_KEY],
        });
      },
    }
  );

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    error:
      mutation.error?.response?.data?.message || "An unexpected error occurred",
    data: mutation.data,
  };
};


export const useUpdateRequestMutation = ({ id, status }) => {
  const queryClient = new useQueryClient();
  const { axios } = useAxios();

  const mutation = useMutation(
    async () => {
      const response = await axios.patch(`${ENDPOINT}/${id}`, { status });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [MY_REDEEM_REQUESTS_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [REDEEM_REQUESTS_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [REWARDS_KEY],
        });
      },
    }
  );

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    error:
      mutation.error?.response?.data?.message || "An unexpected error occurred",
    data: mutation.data,
  };
};
