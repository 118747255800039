import Avatar from "react-avatar";
import {Link} from "react-router-dom";
import Spinner from "../../Spinner";
import PropTypes from "prop-types";

export default function SearchResults({ users, isLoading }) {
  return (
    <div className="absolute top-11 left-6 right-6 bg-white shadow overflow-hidden  rounded-lg px-6">
      {isLoading ? (
        <Spinner />
      ) : (
        <ul role="list" className="divide-y divide-gray-200 ">
          {users.map((user) => (
            <li key={user.user.id} className="py-4">
              <Link to={`/app/profile/${user.user.id}`}>
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <Avatar
                      name={`${user?.user.firstName} ${user?.user.lastName}`}
                      size="40"
                      round={true}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {user.user.firstName} {user.user.lastName}
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      {"@" + user.shortCode}
                    </p>
                  </div>
                  <div></div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

SearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};
