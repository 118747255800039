import {PlusIcon} from "@heroicons/react/solid";
import {PropTypes} from "prop-types";

export default function EmptyState({
  onCreateNew = () => {},
  btnText,
  paragraph,
  paragraph2,
  hint,
  Icon,
}) {
  return (
    <div className="flex flex-col py-12  align-center justify-center text-center border rounded">
      {Icon && <Icon className=" h-12" />}
      <h3 className="mt-2 font-medium text-gray-900">{paragraph}</h3>
      <p className="mt-1 text-sm text-gray-500">{paragraph2}</p>
      <div className="mt-6">
        {btnText && (
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            onClick={onCreateNew}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {btnText}
          </button>
        )}
        <h3 className="mt-6 text-sm font-medium text-gray-500">{hint}</h3>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  onCreateNew: PropTypes.func,
  btnText: PropTypes.string,
  paragraph: PropTypes.string,
  paragraph2: PropTypes.string,
  hint: PropTypes.string,
  Icon: PropTypes.object,
};
