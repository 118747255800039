export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const resolveSelectedMood = (mood) => {
  switch (mood) {
    case 1:
      return "😩";
    case 2:
      return "😕";
    case 3:
      return "😐";
    case 4:
      return "😊";
    case 5:
      return "😍";

    default:
      return "How have you been feeling lately?";
  }
};
