import {useAxios} from "hooks/useAxios";
import {useQuery} from "react-query";
import {skippedMeetingsKeys} from "./keys";

const ENDPOINT = "/v1/meetings/skipped-meetings";

const useSkippedMeetingsQuery = () => {
  const { axios } = useAxios();

  const fetchSkippedMeetings = async () => {
    return axios.get(`${ENDPOINT}`);
  };
  return useQuery(skippedMeetingsKeys.default, fetchSkippedMeetings);
};

export default useSkippedMeetingsQuery;
