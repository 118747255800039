import React, {useState} from "react";
import AddButton from "../../../components/octo-ui/Button/AddButton";
import KudoForm from "./KudoForm";
import KudoTransaction from "./KudoTransaction";
import {useAxios} from "hooks/useAxios";
import {useInfiniteQuery, useQuery} from "react-query";
import PropTypes from "prop-types";

const KudoFeed = ({ user }) => {
  const [KudoFormOpen, setKudoFormOpen] = useState(false);
  const { axios } = useAxios();

  //fetch Members
  const fetchMembers = async () => {
    return await axios.get(
      `/v1/organizations/${user?.organization?.id}/members`
    );
  };

  const fetchedMembers = useQuery(["members", user], fetchMembers, {
    enabled: !!user.organization,
  });
  //end Fetch Members

  //fetch Kudo transactions
  const fetchKudoTransactions = async ({ pageParam = 0, pageSize = 5 }) => {
    return await axios.get(
      `/v1/kudos/?orgId=${user?.organization?.id}&pageNumber=${pageParam}&pageSize=${pageSize}`
    );
  };

  const {
    data: kudosResponse,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(["kudoTransactions", user], fetchKudoTransactions, {
    getNextPageParam: (lastPage) => {
      if (lastPage.data.last) return undefined;
      return lastPage.data.number + 1;
    },
    enabled: !!user.organization,
  });

  var kudos = kudosResponse?.pages
    ?.map((page) => page?.data?.content.map((kudo) => kudo))
    .flat();

  return (
    <div className="rounded-lg bg-white shadow  self-start flex flex-col items-center mr-10">
      <div className="flex items-center justify-between my-4 px-4 w-full">
        <h2 className="text-xl font-semibold">Kudo Feed</h2>
        <AddButton
          onClick={() => setKudoFormOpen(!KudoFormOpen)}
          buttonText="Give Points"
        />
      </div>
      <ol className="relative border-l border-gray-300 dark:border-gray-700 w-full pr-6 ml-12">
        {KudoFormOpen && (
          <KudoForm
            onClose={() => setKudoFormOpen(false)}
            members={fetchedMembers?.data?.data?.filter(
              (x) => x?.user.email !== user?.user?.email
            )}
            user={user}
          />
        )}

        {kudos?.map((transaction) => (
          <KudoTransaction key={transaction.id} transaction={transaction} />
        ))}
      </ol>
      {hasNextPage && (
        <button
          className="my-4 w-1/3 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage ? "Loading more..." : "Load More"}
        </button>
      )}
      <div>{isFetching && !isFetchingNextPage ? "Loading..." : null}</div>
    </div>
  );
};

export default KudoFeed;

KudoFeed.propTypes = {
  user: PropTypes.object,
};
