import React, {useEffect, useState} from 'react'
// import BarChart from './BarChart'
import {useQuery} from 'react-query';
import {useAxios} from 'hooks/useAxios';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import {ReactComponent as Crown} from '../../../assets/crown.svg';
import confetti from '../../../assets/party.png';

const KudoLeaderboard = ({ user }) => {

    const { axios } = useAxios();


    //fetch Members
    const fetchMembers = async () => {
        return await axios.get(
            `/v1/organizations/${user?.organization?.id}/members`
        );
    };
    const fetchedMembers = useQuery(["members", user], fetchMembers, {
        enabled: !!user.organization,
    });
    //end Fetch Members
    const [members, setMembers] = useState({ labels: [], data: [] });

    useEffect(() => {

        let temp = fetchedMembers?.data?.data?.sort((a, b) => {
            return b.kudoPoints - a.kudoPoints
        }).slice(0, 5);
        setMembers({
            labels: temp?.map(x => x?.user?.firstName + " " + x?.user?.lastName),
            data: temp?.map(x => x?.kudoPoints)
        });
    }, [fetchedMembers?.data?.data]);

    return (

        <div className="rounded-lg bg-white shadow  flex flex-col items-center relative">
            {members?.data?.length > 0 && <img src={confetti} className='w-full absolute opacity-20 z-0 ' />}
            <div className="flex flex-col justify-between my-4 px-4 w-full">
                <h2 className="text-xl font-semibold my-4 mx-2 z-10 w-fit bg-white">Kudo Leaderboard</h2>
                <div className="self-center flex w-[55%] justify-center items-center relative h-60" >
                    {members?.data?.[1] != undefined &&
                        <div className=' w-24 pb-2 px-1 bg-slate-200 shadow-xl absolute left-0 flex flex-col items-center justify-end  rounded-tl-[100px] rounded-tr-[100px] rounded-br-[1rem] rounded-bl-[1rem]'>
                            <Avatar name={members?.labels?.[1]} round size='80' className='mt-1 mb-2' />
                            <p className='text-center font-bold text-slate-600'>{members?.labels?.[1]}</p>
                        </div>
                    }
                    {members?.data?.[0] != undefined &&
                        <div className=' w-24 pb-2 px-1 bg-amber-200 z-10 shadow-xl absolute flex flex-col items-center justify-end  rounded-tl-[100px] rounded-tr-[100px] rounded-br-[1rem] rounded-bl-[1rem] scale-125'>
                            <Crown className='absolute top-[-2rem] h-8 fill-amber-400 ' />
                            <Avatar name={members?.labels?.[0]} round size='80' className='mt-1 mb-2' />
                            <p className='text-center font-bold text-yellow-700'>{members?.labels?.[0]}</p>
                        </div>
                    }
                    {members?.data?.[2] != undefined &&
                        <div className=' w-24 pb-2 px-1 bg-orange-200 shadow-xl absolute right-0 flex flex-col items-center justify-end  rounded-tl-[100px] rounded-tr-[100px] rounded-br-[1rem] rounded-bl-[1rem]'>
                            <Avatar name={members?.labels?.[2]} round size='80' className='mt-1 mb-2' />
                            <p className='text-center font-bold text-orange-700'>{members?.labels?.[2]}</p>
                        </div>
                    }
                </div>
                <ul>
                    {members?.data?.map((x, i) => {
                            return <li className='my-3' key={i}>
                                <div className={`flex items-center justify-between bg-${i==0?"amber":i==1?"slate":i==2?"orange":null}-100 px-4 py-2 rounded-xl shadow-md`}>
                                    <div className='flex items-center'>
                                        <h3 className={`font-bold text-xl text-${i==0?"amber":i==1?"slate":i==2?"orange":"gray"}-700 mr-4 w-10`}>{i==0?1+"st":i==1?2+"nd":i==2?3+"rd":(i+1)+"th"}</h3>
                                        <Avatar name={members?.labels?.[i]} round size='30' />
                                        <p className={`ml-2 font-semibold text-${i==0?"amber":i==1?"slate":i==2?"orange":"gray"}-800`}>{members?.labels?.[i]}</p>
                                    </div>
                                    <p className={`font-bold text-lg text-${i==0?"amber":i==1?"slate":i==2?"orange":"gray"}-700`}>{x} Points</p>
                                </div>
                            </li>
                        
                    })}
                </ul>
            </div>
        </div>
    )
}

export default KudoLeaderboard

KudoLeaderboard.propTypes = {
    user: PropTypes.object
}