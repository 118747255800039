/* eslint-disable react/prop-types */
/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useState} from "react";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "utils/helpers";

export default function SelectWithSupportedText({
  func,
  options: publishingOptions,
}) {
  const [selected, setSelected] = useState(publishingOptions[0]);

  func(selected.title);

  return (
    <Listbox value={selected} onChange={setSelected} data-testid="selectinput">
      {({ open }) => (
        <>
          <div className="relative">
            <div className="inline-flex shadow-sm rounded-md divide-x divide-brand-600">
              <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-brand-600">
                <div className="relative inline-flex items-center bg-brand-500 py-1 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                  <p className="ml-2.5 text-xs font-medium">{selected.title}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-brand-500 p-1 rounded-l-none rounded-r-md text-xs font-medium text-white hover:bg-brand-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-500">
                  <ChevronDownIcon
                    data-testid="chevron"
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-brand-500" : "text-gray-900",
                        "cursor-default select-none relative py-2 px-4 text-xs"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={selected ? "font-bold" : "font-semibold"}
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? "text-white" : "text-brand-500"
                              }
                            >
                              <CheckIcon
                                className="h-3 w-3"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-brand-200" : "text-gray-500",
                            "mt-1"
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
