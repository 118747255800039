import {ExclamationCircleIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";

export default function TextWithError({
  placeholder,
  errorMsg,
  className,
  label,
  register,
  name,
  errors,
  dataTestId,
}) {
  return (
    <div className={className}>
      <label htmlFor="text" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-3 relative rounded-md shadow-sm">
        <input
          {...register(name, { required: errorMsg })}
          type="text"
          name={name}
          data-testid={dataTestId}
          className={`block w-full pr-10 ${
            errors != undefined
              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 text-gray-900 placeholder-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500"
          } sm:text-sm rounded-md`}
          placeholder={placeholder}
        />
        {errors != undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {errors != undefined && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors.message}
        </p>
      )}
    </div>
  );
}

TextWithError.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  register: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  errorMsg: PropTypes.string,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
};
