import NotFoundPage from "../pages/NotFoundPage";
import App from "../pages/App";
import Home from "../pages/Home";
import Team from "../pages/Team";
import Rewards from "../pages/Rewards";
import RedeemPage from "../pages/Rewards/RedeemPage";
import RedeemRequestsPage from "pages/Rewards/RedeemRequestsPage";
import Feedback from "../pages/Feedback";
import Events from "../pages/Events";
import UserProfile from "../pages/UserProfile";
import Reports from "pages/Reports";

const routes = [
  {
    path: "/app/profile/:id",
    component: UserProfile,
    isPrivate: true,
  },
  {
    path: "/app",
    component: App,
    isPrivate: true,
  },
  {
    path: "/app/team",
    component: Team,
    isPrivate: true,
  },
  {
    path: "/app/feedback",
    component: Feedback,
    isPrivate: true,
  },
  {
    path: "/app/meeting",
    component: Events,
    isPrivate: true,
  },
  {
    path: "/app/rewards",
    component: Rewards,
    isPrivate: true,
  },
  {
    path: "/app/rewards/:id/redeem",
    component: RedeemPage,
    isPrivate: true,
  },
  {
    path: "/app/redeem-requests",
    component: RedeemRequestsPage,
    isPrivate: true,
  },{
    path: "/app/reports",
    component: Reports,
    isPrivate: true,
  },
  {
    path: "/",
    component: Home,
  },
  {
    path: "*",
    component: NotFoundPage,
  },
];

export default routes;
