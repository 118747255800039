import {useState} from "react";
import PropTypes from "prop-types";
import {InformationCircleIcon, XIcon} from "@heroicons/react/solid";
import {resolveSelectedMood} from "utils/helpers";

export const MoodSelector = ({ selectedMood, onSelectMood }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClickMood = (mood) => {
    onSelectMood(mood, () => {
      setIsOpen(false);
    });
  };
  const [tooltip, setTooltip] = useState(false);

  return (
    <div className="flex items-center gap-2 relative">
      {isOpen ? (
        <div className="flex text-4xl gap-2 transition-all ease duration-200 bg-brand-50 rounded-lg px-4 py-1">
          {[...Array(5)].map((_, index) => (
            <button
              key={index}
              className={`hover:scale-110 origin-center transition-all ease duration-300 
                  ${
                    selectedMood == index + 1 ? "" : "saturate-0"
                  } hover:saturate-100`}
              onClick={() => handleClickMood(index + 1)}
            >
              {resolveSelectedMood(index + 1)}
            </button>
          ))}
          <button onClick={() => setIsOpen(false)}>
            <XIcon className="w-4" />
          </button>
        </div>
      ) : (
        <>
          {tooltip && (
            <div className="absolute z-10 bottom-[-80px] w-64 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700">
              <p>
                Click here to collect and track the mood of the person you manage. This information helps us understand and address Nemos well-being and make necessary improvements to enhance the overall work environment.
              </p>
            </div>
          )}
          <button
            onMouseEnter={() => setTooltip(!selectedMood)}
            onMouseLeave={() => setTooltip(false)}
            onClick={() => setIsOpen(true)}
            className={`flex items-center gap-2 hover:translate-y-[-2px] transition-translate ease duration-300
            bg-brand-50 rounded-lg px-4 py-1 text-brand-800 ${
              selectedMood ? "text-4xl" : "text-sm"
            } font-bold `}
          >
            <p> {resolveSelectedMood(selectedMood)} </p>
            {!selectedMood && <InformationCircleIcon className="h-4 ml-auto" />}
          </button>
        </>
      )}
    </div>
  );
};
MoodSelector.propTypes = {
  onSelectMood: PropTypes.func,
  selectedMood: PropTypes.number,
};
