import React from "react";
import KudoLeaderboard from "./KudoLeaderboard";
import KudoFeed from "./KudoFeed";
import KudoMetrics from "./KudoMetrics";
import PropTypes from "prop-types";

const Kudo = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <div className="w-full flex flex-col md:flex-row space-x-5">
      <KudoFeed user={user} />
      <div className="my-4 md:my-0">
        <KudoLeaderboard user={user} />
        <KudoMetrics user={user} />
      </div>
    </div>
  );
};

Kudo.propTypes = {
  user: PropTypes.object,
};

export default Kudo;
