/* eslint-disable no-useless-escape */
import React, {useState} from "react";
import AddButton from "components/octo-ui/Button/AddButton";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";
import PropTypes from "prop-types";
import {useAuth} from "hooks/useAuth";
import SuccessAlert from "components/Alerts/SuccessAlert";
import {
  ExclamationCircleIcon,
  MailIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import SelectWithSupportedText
  from "components/Forms/Select/SelectWithSupportedText";

const InviteUsersForm = ({ setClose, SetdisplayAlert }) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");

  const queryClient = useQueryClient();
  const { user } = useAuth();

  const { axios } = useAxios();

  const inviteUsersCall = async () => {
    return axios.post(
      `/v1/organizations/${user.organization.id}/invitations`,
      selectedEmails
    );
  };

  const {
    isSuccess,
    isLoading,
    mutate: inviteUsers,
  } = useMutation(inviteUsersCall, {
    onSuccess: () => {
      queryClient.refetchQueries(["fetchInvites"]);
      setClose(false);
      SetdisplayAlert();
    },
  });

  const postchange = () => {
    inviteUsers();
  };

  //EMAIL INPUT CONTROL

  const onChange = (event) => {
    setInputValue(event.target.value);
    setInputError("");
  };

  //EMAIL VALIDATION

  const isValid = (email) => {
    if (isInList(email)) {
      setInputError(`${email} has already been added.`);
      return false;
    }

    if (!isEmail(email)) {
      setInputError(`${email} is not a valid email address.`);
      return false;
    }
    return true;
  };

  const isInList = (email) => {
    return selectedEmails.some((obj) => obj.email == email);
  };

  const isEmail = (email) => {
    if (email.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)) return true;
    else return false;
  };

  const popFromSelected = (obj) => {
    setSelectedEmails(selectedEmails.filter((item) => item !== obj));
  };

  //EVENTS HANDLERS

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      if (inputValue.trim() !== "" && isValid(inputValue.trim())) {
        setSelectedEmails((selectedEmails) => [
          ...selectedEmails,
          { email: inputValue.trim(), role: "USER" },
        ]);
        setInputValue("");
      } else {
        return;
      }
    }
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));
      toBeAdded.map((email) => {
        setSelectedEmails((selectedEmails) => [
          ...selectedEmails,
          { email: email, role: "USER" },
        ]);
      });
    }
    setInputValue("");
  };

  return (
    <>
      {isSuccess && <SuccessAlert />}
      <div className="mt-3 relative rounded-md shadow-sm ">
        <p className="text-xs text-gray-600 my-2">Enter emails </p>
        <input
          className={`block w-full pr-10 ${
            inputError !== ""
              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 text-gray-900 placeholder-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500"
          }  sm:text-sm rounded-md`}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          onPaste={handlePaste}
          data-testid="email-list"
        />
        {inputError !== "" && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
      {inputError !== "" && (
        <p className="mt-2 text-sm text-red-600">{inputError}</p>
      )}

      <div className="container my-4">
        <ul role="list" className="space-y-2">
          {selectedEmails.map((item) => {
            const pullValue = (selected) => {
              item.role = selected;
            };

            return (
              <li
                key={selectedEmails.indexOf(item)}
                className="bg-white shadow rounded-md px-6 py-4 flex justify-between items-center text-sm text-gray-700 overflow-visible"
              >
                <p className="flex items-center w-6/12">
                  <MailIcon className="h-4 mr-2" /> {item.email}
                </p>

                <SelectWithSupportedText
                  options={[
                    {
                      title: "USER",
                      description: "Can only view members.",
                      current: true,
                    },
                    {
                      title: "ADMIN",
                      description: "Can View, Add, Edit, Delete members.",
                      current: false,
                    },
                  ]}
                  func={pullValue}
                />

                <TrashIcon
                  onClick={() => {
                    popFromSelected(item);
                  }}
                  className="text-red-700 h-4"
                  data-testid="trash"
                />
              </li>
            );
          })}
        </ul>
      </div>
      <AddButton
        buttonText="Invite Members"
        isLoading={isLoading}
        onClick={postchange}
      />
    </>
  );
};

export default InviteUsersForm;
InviteUsersForm.propTypes = {
  setClose: PropTypes.func,
  SetdisplayAlert: PropTypes.func,
};
