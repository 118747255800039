import React from "react";
import PropTypes from "prop-types";
import {useAuth} from "hooks/useAuth";
import PageLoader from "components/PageLoader";

function PrivateRoute({ component: Component }) {
  const { isAuthenticated, initialized, user, login } = useAuth();

  if (!initialized) {
    return <PageLoader />;
  }
  if (!isAuthenticated) {
    return login();
  }
  if (!user) {
    return <PageLoader />;
  }

  return <Component />;
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
