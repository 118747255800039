import {CheckCircleIcon, XIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import PropTypes from "prop-types";

const SuccessAlert = ({ message }) => {
  const [isDisplayed, setDisplay] = useState(true);
  const closeAlert = () => {
    setDisplay(false);
  };

  return (
    <>
      {isDisplayed && (
        <div className={`fixed w-96 z-50 top-20 ml-auto mr-auto left-0 right-0 text-center rounded-md bg-green-50 p-4`}>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">{message}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    onClick={closeAlert}
                    data-testid="close"
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessAlert;
SuccessAlert.propTypes = {
  message: PropTypes.string,
  position: PropTypes.string,
};
