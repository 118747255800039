import {React, useEffect, useState} from "react";
import {DocumentReportIcon} from "@heroicons/react/outline";
import Spinner from "components/Spinner";
import EmptyState from "components/Empty-state";
import Sidebar from "components/Nav/Sidebar";
import {useGetReports} from "./useReports";

const Reports = () => {
  const [current, setCurrent] = useState(null);
  const { data: reports, isLoading, isError } = useGetReports();

  useEffect(() => {
    if (reports && reports.length > 0 && current === null) {
      setCurrent(reports[0].id);
    }
  }, [reports]);

  if (isLoading) {
    return (
      <div className="pt-16 flex flex-col">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div>Error loading reports list.</div>;
  }

  if (!reports || reports.length === 0) {
    return (
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <EmptyState
          paragraph="No reports available right now"
          paragraph2={"Please check back later, or contact the administrator"}
        />
      </div>
    );
  }

  const navigation = reports.map((report) => ({
    id: report.id,
    name: report.name,
    href: "#",
    icon: DocumentReportIcon,
  }));

  const currentReport = reports.find((report) => report.id === current);

  return (
    <div className="pt-16 flex flex-col">
      <Sidebar
        navigation={navigation}
        current={current}
        setCurrent={setCurrent}
      />
      <main className="md:pl-64 flex grow">
        <div className={`py-6 grow min-h-[92.5vh]`}>
          {currentReport && (
            <div className="max-w-7xl h-full mx-auto">
              <h1 className="text-2xl font-semibold text-gray-900 my-8">
                {currentReport.name}
              </h1>

              <iframe
                src={currentReport.url}
                title={currentReport.name}
                className="w-full h-full border-none bg-transparent"
              ></iframe>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Reports;
