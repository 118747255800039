import {useQuery} from "react-query";
import {Link} from "react-router-dom";
import {CollectionIcon} from "@heroicons/react/outline";
import PropTypes from "prop-types";
import {useAxios} from "hooks/useAxios";
import Feed from "components/Feeds";

export default function O3Prepare({ currentUser }) {
  const { axios } = useAxios();

  const fetchMeetings = async () => {
    return axios.get(`/v1/meetings`);
  };
  const fetchedMeetings = useQuery(["meetings"], fetchMeetings);
  const userEmail = currentUser?.user?.email;

  const filterMeetings = (meetings) => {
    return meetings?.filter(
      (meeting) =>
        meeting.participant.email === userEmail ||
        meeting.owner.email === userEmail
    );
  };
  const filteredMeetings = filterMeetings(fetchedMeetings?.data?.data);
  return (
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2
          className="text-base font-medium text-gray-900"
          id="recent-hires-title"
        >
          O3 to prepare for
        </h2>
        {filteredMeetings?.length > 0 ? (
          <Feed list={filteredMeetings} userEmail={userEmail} />
        ) : (
          <div className="flex justify-center items-center">
            <CollectionIcon className="text-gray-200  h-12 w-12 my-4" />
          </div>
        )}

        {filteredMeetings?.length > 3 && (
          <div className="mt-6">
            <Link
              to="/app/meeting"
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              View all
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
O3Prepare.propTypes = {
  currentUser: PropTypes.object,
};
