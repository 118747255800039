import {useEffect, useState} from "react";
import {PencilAltIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {useMutation} from "react-query";
import Avatar from "react-avatar";
import {useAxios} from "hooks/useAxios";
import {useForm} from "react-hook-form";
// import { useParams } from "react-router-dom";
import SelectManager from "components/Forms/Select/SelectManager";
import Spinner from "components/Spinner";

const CurrentUserDetails = ({
  user: info,
  isEdit,
  setIsEdit,
  setDispledAlert,
}) => {
  const { axios } = useAxios();
  const [user, setUser] = useState({});
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const fetchMembers = async () => {
    await axios
      .get(`/v1/organizations/${info.organization?.id}/members`)
      .then((res) => setOrganizationMembers(res.data));
  };

  useEffect(() => {
    if (info !== null && info !== undefined) {
      if (Object.keys(info).length === 0) {
        return;
      }
    }

    reset({
      firstName: info?.user.firstName,
      lastName: info?.user.lastName,
      shortCode: info?.organizationMembers?.shortCode,
      discordUsername: info?.organizationMembers?.discordUsername,
      phone: info?.user.phone,
      bio: info?.user?.bio,
      position: info?.organizationMembers?.position,
      manager: info?.organizationMembers?.manager?.user,
    });
    setUser(info);
    fetchMembers();
  }, [info]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [requestedData, setRequestedData] = useState([]);

  const callApi = async () => {
    return axios.put("/v1/user-profile", requestedData);
  };
  const { mutate: updateProfile, isLoading } = useMutation(callApi, {
    onSuccess: (res) => {
      setDispledAlert();
      setUser(res.data);
      setIsEdit(false);
    },
  });

  const Onsubmit = (data) => {
    setRequestedData({
      ...data,
      email: info.user.email,
      manager: data.manager.id,
    });

    updateProfile();
  };
  return (
    <>
      <div className="min-h-full  mt-8">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <Avatar
                  name={`${user?.user?.firstName} ${user?.user?.lastName}`}
                  className="rounded-full"
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {`${user?.user?.lastName} , ${user?.user?.firstName}`}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Joined{" "}
                <span className="font-bold">
                  {" "}
                  {user?.organization?.contactName}
                </span>{" "}
                on <time dateTime="2020-08-25">August 25, 2020</time>
              </p>
            </div>
          </div>
          {user?.organizationMembers?.role === "ADMIN" && (
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-500"
              >
                Suspend Account
              </button>

              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Edit
              </button>
            </div>
          )}
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-3">
            {/* Description list*/}
            <form
              aria-labelledby="applicant-information-title  bg-red-300"
              onSubmit={handleSubmit((data) => Onsubmit(data))}
            >
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Personal Information
                  </h2>

                  <a
                    className={`${
                      isEdit ? "text-blue-600" : "text-gray-600"
                    } selection:col-start-3 col-end-3 justify-self-end group inline-flex items-center  px-1 font-medium text-sm cursor-pointer hover:text-indigo-600`}
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    <PencilAltIcon
                      className={` -ml-0.5 mr-2 h-5 w-5`}
                      aria-hidden="true"
                    />
                    <span>Edit</span>
                  </a>

                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {!isEdit && (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {`${user?.user?.email} `}
                        </dd>
                      </div>
                    )}
                   

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Full name <span className="text-red-500">*</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit &&
                          `${user?.user?.firstName} ${user?.user?.lastName}`}
                        {isEdit && (
                          <div className="flex">
                            <input
                              type="text"
                              name="first-name"
                              {...register("firstName", {
                                required: "first Name is required",
                              })}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 mr-2 block  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                              {errors.firstName?.message}
                            </p>
                            <>
                              <input
                                type="text"
                                name="last-name"
                                {...register("lastName", {
                                  required: "last Name is required",
                                })}
                                id="last-name"
                                autoComplete="lastName"
                                className="mt-1 block  py-2 px-3  border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                              <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                                {errors.lastName?.message}
                              </p>
                            </>
                          </div>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Short Code <span className="text-red-500">*</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit &&
                          user?.organizationMembers?.shortCode &&
                          `${user?.organizationMembers?.shortCode} `}
                        {!isEdit &&
                          !user?.organizationMembers?.shortCode &&
                          "add a short Code"}
                        {isEdit && (
                          <div className="col-span-6 sm:col-span-3">
                            <input
                              type="text"
                              data-testid="shortcode"
                              name="shortCode"
                              {...register("shortCode", {
                                required: "Short Code is required",
                              })}
                              id="shortCode"
                              autoComplete="shortCode"
                              className="mt-1 block  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                              {errors.shortCode?.message}
                            </p>
                          </div>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Discord Username
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {!isEdit &&
                          user?.organizationMembers?.discordUsername &&
                          `${user?.organizationMembers?.discordUsername} `}
                        {!isEdit &&
                          !user?.organizationMembers?.discordUsername &&
                          "add a discord username"}
                        {isEdit && (
                          <div className="col-span-6 sm:col-span-3">
                            <input
                              type="text"
                              data-testid="discordUsername"
                              name="discordUsername"
                              {...register("discordUsername")}
                              id="discordUsername"
                              autoComplete="discordUsername"
                              className="mt-1 block  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                            />
                            <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                              {errors.discordUsername?.message}
                            </p>
                          </div>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Position
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit && user?.organizationMembers?.position}
                        {!isEdit &&
                          !user?.organizationMembers?.position &&
                          "select your position"}
                        {isEdit && (
                          <>
                            <input
                              type="text"
                              name="position"
                              data-testid="position"
                              {...register("position")}
                              id="position"
                              autoComplete="position"
                              className="mt-1 block  py-2 px-3  border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                              {errors.position?.message}
                            </p>
                          </>
                        )}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit && `${user?.user?.phone}`}
                        {!isEdit &&
                          !user?.user?.phone &&
                          "add your phone number"}
                        {isEdit && (
                          <div className="col-span-6 sm:col-span-3">
                            <input
                              type="tel"
                              data-testid="phone"
                              name="phone"
                              {...register("phone", {
                                pattern: {
                                  value:
                                    /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g,
                                  message: "phone is invalid ",
                                },
                              })}
                              id="phone"
                              autoComplete="phone"
                              className="mt-1 block  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <p className="text-sm font-light leading-relaxed mt-0 mb-4 text-red-500">
                              {errors.phone?.message}
                            </p>
                          </div>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Manager
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit &&
                          user?.organizationMembers?.manager &&
                          `${user?.organizationMembers?.manager?.firstName} ${user?.organizationMembers?.manager?.lastName}`}

                        {!isEdit &&
                          !user?.organizationMembers?.manager?.lastName &&
                          "add your manager"}

                        {isEdit && (
                          <SelectManager
                            people={organizationMembers.filter(
                              (member) => member?.user?.id !== user?.user?.id
                            )}
                            defaultValue={
                              user?.organizationMembers?.manager != null
                                ? organizationMembers.find(
                                    (member) =>
                                      member.user.id ===
                                      user?.organizationMembers?.manager?.id
                                  )?.user
                                : organizationMembers[0]?.user
                            }
                            control={control}
                            name="manager"
                          />
                        )}
                      </dd>
                    </div>

                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Bio</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {!isEdit && user?.user?.bio}
                        {isEdit && (
                          <div className="col-span-6 sm:col-span-6">
                            <textarea
                              id="bio"
                              name="bio"
                              {...register("bio")}
                              rows="4"
                              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Bio ,links ...."
                            />
                            {isEdit && (
                              <button
                                type="submit"
                                disabled={false}
                                className="inline-flex mt-2 items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Save {isLoading && <Spinner />}
                              </button>
                            )}
                          </div>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </form>

            {/* Comments*/}
            {/* <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Empty card
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6"></div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6"></div>
              </div>
            </section> */}
          </div>

          {/* <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                Empty Card
              </h2>

              {/* Activity Feed 
              <div className="mt-6 flow-root">
                <ul role="list" className="-mb-8"></ul>
              </div>
            </div>
          </section> 
          */}
        </div>
      </div>
    </>
  );
};

export default CurrentUserDetails;

CurrentUserDetails.propTypes = {
  user: PropTypes.object,
  isEdit: PropTypes.bool,
  setDispledAlert: PropTypes.func,
  setIsEdit: PropTypes.func,
};
