import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CollectionIcon} from "@heroicons/react/outline";
import Feed from "components/Feeds";

import useSkippedMeetingsQuery from "../hooks/useSkippedMeetingsQuery";

export default function NeedMoreAttention({currentUser }) {
  const userEmail = currentUser?.user?.email;
  const fetchedMeetings = useSkippedMeetingsQuery();
  const meetings = fetchedMeetings?.data?.data;

  return (
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2
          className="text-base font-medium text-gray-900"
          id="recent-hires-title"
        >
          Need more attention
        </h2>
        {meetings?.length > 0 ? (
           <Feed list={meetings} userEmail={userEmail} />
        ) : (
          <div className="flex justify-center items-center">
            <CollectionIcon className="text-gray-200  h-12 w-12 my-4" />
          </div>
        )}

        {meetings?.length > 3 && (
          <div className="mt-6">
            <Link
              to="/app/meeting"
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              View all
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
NeedMoreAttention.propTypes = {
  meetings: PropTypes.array,
  currentUser: PropTypes.object,
};
