import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {InformationCircleIcon} from "@heroicons/react/solid";
import EmptyState from "components/Empty-state";
import {useAuth} from "hooks/useAuth";
import {useGetMyRedeemRequests} from "./useRedeemRequests";
import {useGetRewards} from "./useRewards";
import RequestsList from "./RequestsList";

const Rewards = () => {
  const { user } = useAuth();

  const { data: rewards, isLoading, isError } = useGetRewards();

  const { data: redeemRequests, isLoading: isRedeemRequestsLoading } =
    useGetMyRedeemRequests({ userId: user?.user?.id });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading rewards.</div>;
  }

  if (!rewards || rewards.length === 0) {
    return (
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <EmptyState
          paragraph="No Rewards available right now"
          paragraph2={"Please check back later, or contact your manager"}
        />
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <section>
        <h1 className="text-2xl font-semibold text-gray-900 my-8">
          Redeem your Kudo points
        </h1>

        <div className="rounded-md bg-blue-100 p-4 mb-10">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                You have{" "}
                <span className="font-bold">
                  {user?.organizationMembers?.kudoPoints}
                </span>{" "}
                Kudo points to redeem.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {rewards.map((reward) => (
            <RewardCard
              key={reward.id}
              reward={reward}
              disabled={user?.organizationMembers?.kudoPoints < reward?.points}
            />
          ))}
        </div>
      </section>

      <section className="mt-16">
        <h1 className="text-2xl font-semibold text-gray-900 my-8">
          My Requests History
        </h1>

        <RequestsList
          requests={redeemRequests}
          isLoading={isRedeemRequestsLoading}
          role={user?.organizationMembers?.role}
        />
      </section>
    </div>
  );
};

const RewardCard = ({ reward, disabled}) => {
  const notAvailable = disabled && (reward.status === "disabled" || reward.quantity === 0);
  return (
    <div
      key={reward.id}
      className={`group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white ${
        notAvailable ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <div className="aspect-w-3 aspect-h-4 bg-gray-200 sm:aspect-none sm:h-60">
        <img
          src={reward.image}
          alt={reward.name}
          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-md font-medium text-gray-900">{reward.name}</h3>
        <p className="text-sm text-gray-500">{reward.description}</p>

        <div className="pt-4 flex flex-1 justify-between">
          <p className="text-base font-medium text-gray-900">
            {reward.points} points
          </p>
          <p className="text-sm text-green-500">{reward.quantity} Available</p>
        </div>
        <div className="mt-6">
          <Link
            to={disabled ? "#" : `/app/rewards/${reward.id}/redeem`}
            disabled={disabled}
            className={`relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 py-2 px-8 text-sm font-medium text-gray-900 ${
              disabled
                ? 'cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none hover:bg-gray-100'
                : 'hover:bg-brand-500'
            }`}
          >
            Redeem<span className="sr-only">, {reward.name}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

RewardCard.propTypes = {
  reward: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
    description: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default Rewards;
